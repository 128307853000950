import React from "react";
// import styled from "styled-components";
import TutorialFirst from "../../components/Tutorial-Screen/TutorialFirst";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Outlet } from "react-router-dom";
import WelcomeText from "../../components/WelcomeText";
// import SearchIcon from '@mui/icons-material/Search';

let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const Tutorial = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          <Box sx={{ borderBottom: "2px solid #671E78" }}>
            <WelcomeText />
            {/* <Typography
              variant="p"
              sx={{ lineHeight: "23px", fontSize: "20px" }}
            >
              Hi there! Welcome
            </Typography> */}
            <Typography
              variant="h1"
              sx={{
                mt: 3,
                fontSize: {
                  lg: "42px",
                  md: "42px",
                  sm: "38px",
                  xs: "30px",
                },
              }}
            >
              Tutorial/Resources
            </Typography>
          </Box>
          {/* .......,,,,,,,,,,,,,,.............Tables..............,,,,,,,,,,,,,,,,............... */}
        </Box>
        <Outlet />
      </ThemeProvider>
    </>
  );
};

export default Tutorial;
