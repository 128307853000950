import React, { useState, useEffect } from "react";
import {
  AuthWrapper,
  AuthLeftWrapper,
  LeftWrapperContent,
  LogoWrapper,
  HeaderWrapper,
  BigText,
  MediumText,
  SmallText,
  InputWrapper,
  AuthButton,
  ButtonFlex,
} from "./auth.style";
import { connect } from "react-redux";
import colors from "../../assets001/colors.json";
import GlobalStyles from "../../assets001/globalStyle";
import LoginIcon from "../../assets001/icons/Login";
import { useNavigate, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReactCodeInput from "react-code-input";
import logo from "../../assets001/school-logo.png";
import "../../assets001/style.css";
import "react-phone-input-2/lib/style.css";
import HomeIcon from "../../assets001/icons/home";
import RightSide from "../../components/RightSide";
import { useMediaQuery } from "react-responsive";
import { studentLogin } from "../../actions/user";
import { toast } from "react-toastify";
import FormLoader from "../../components/FormLoader";

const Login = ({ studentLogin }) => {
  const phoneNumber = localStorage.lasmabPhone || "";
  const browserToken = localStorage.lasmabBrowserToken || "";
  const accountType = localStorage.lasmabAccountType || "";
  const dialCode = localStorage.dialCode || "";

  const [code, setCode] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const navigate = useNavigate();
  const onFormButton = async (e) => {
    e.preventDefault();
    onFormSubmit();
  };
  const onFormSubmit = async () => {
    setFormLoading(true);
    try {
      let res = await studentLogin(
        phoneNumber,
        code,
        browserToken,
        accountType
      );
      console.log(res);

      // track 401 error
      if (res.status === 401) {
        console.log("here");
        toast.error(res.data.message);
        console.log(res.data);
        if (res.data.statusCode === 54) {
          console.log("inside");
          localStorage.removeItem("lasmabBrowserToken");
          localStorage.removeItem("lasmabPhone");
          localStorage.removeItem("accountType");
          navigate("/verify");
          return;
        }
        setFormLoading(false);
        return;
      }

      if (res.status === true && res.data.isLogin === true) {
        console.log(res.data.authToken);
        let studentObj = {
          classId: res.data.classId,
          schoolId: res.data.schoolId,
          studentImage: res.data.studentImagePath,
          userId: res.data.studentId,
        };
        localStorage.lasmabAuthToken = res.data.authToken;
        localStorage.setItem("lasmabObj", JSON.stringify(studentObj));
        localStorage.lasmabAuthStatus = res.data.isLogin;
        setFormLoading(false);
        toast.success(res.message);
        navigate("/dashboard");
      } else {
        setFormLoading(false);
        toast.error(res?.data?.message);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An Error Occured Try Again Later");
      setFormLoading(false);
    }
  };
  useEffect(() => {
    if (code.length === 6) {
      onFormSubmit();
    }
  }, [code]);

  useEffect(() => {
    if (phoneNumber === "" || browserToken === "" || accountType === "") {
      navigate("/verify");
    }
  }, []);

  return (
    <AuthWrapper>
      <GlobalStyles />
      <AuthLeftWrapper>
        <LeftWrapperContent>
          <LogoWrapper>
            <img src={logo} alt="" />
            <div>
              <p>Lagos Anglican Schools</p>
              <p>Management Board</p>
            </div>
          </LogoWrapper>
          <HeaderWrapper>
            <BigText>Student Portal Login</BigText>
            <MediumText>
              Welcome back!. Log in with your credentials.
            </MediumText>
          </HeaderWrapper>

          <form
            onSubmit={(e) => onFormButton(e)}
            style={{
              marginTop: "20px",
            }}
          >
            {/* <InputWrapper>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </InputWrapper> */}
            <InputWrapper>
              <label htmlFor="phone number">Phone Number</label>
              <PhoneInput
                disabled={true}
                inputClass={"phone-input"}
                buttonClass={"phone-btn"}
                country={"ng"}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                // eslint-disable-next-line no-useless-concat
                value={`${dialCode}` + `${phoneNumber.slice(1)}`}
                onChange={(phone, country) => {
                  let input = phone;
                  if (country.countryCode === "ng") {
                    if (input.slice(0, 3) === "234") {
                      const a = input.slice(3);
                      let b = a[0];
                      if (b !== "0") {
                        b = `0${a}`;
                      } else {
                        input = a;
                      }
                    }
                  }
                  // setPhoneNumber(input);
                  // setDialCode(country.dialCode);
                  // setCountry(country.countryCode);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="">Pin</label>
              <ReactCodeInput
                disabled={formLoading}
                type="password"
                className="code-input"
                fields={6}
                value={code}
                onChange={(e) => {
                  setCode(e);
                }}
              />
            </InputWrapper>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {" "}
              <SmallText
                style={{
                  textAlign: "right",
                }}
              >
                <Link
                  to="/resetpassword"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </Link>
              </SmallText>
            </div>

            <ButtonFlex>
              <AuthButton
                as="a"
                href="https://lasmab.org/"
                // target="_blank"
                // disabled={false}
                width={isMobile ? "100%" : "35%"}
                // onClick={null}
                color={colors.primary}
                bg="white"
              >
                {" "}
                <div>
                  <HomeIcon />
                  <span>Back To Home</span>
                </div>
              </AuthButton>

              <AuthButton
                disabled={formLoading || code.length < 6}
                width={isMobile ? "100%" : "60%"}
              >
                {formLoading ? (
                  <FormLoader />
                ) : (
                  <div>
                    <LoginIcon />
                    <span>Login</span>
                  </div>
                )}

                {/* Send Code */}
              </AuthButton>
            </ButtonFlex>
          </form>
          <SmallText
            style={{
              textAlign: "center",
            }}
          >
            Having Problem With Your Account?{" "}
            <Link
              to="/verify"
              style={{
                fontWeight: "bold",
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              RESET NOW
            </Link>
          </SmallText>
        </LeftWrapperContent>
      </AuthLeftWrapper>
      <RightSide />
    </AuthWrapper>
  );
};

export default connect(null, { studentLogin })(Login);
