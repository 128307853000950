import React, { useState, useEffect, useCallback } from "react";
import {
  FormFlex,
  FormFlexDiv,
  PageWrapper,
  AccordionText,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import colors from "../../assets001/colors.json";
import Loader from "../pageLoader/loader";
import { getStudentMedicalInfo } from "../../actions/user";
import { InputWrapper } from "../../pages/auth/auth.style";
import { toast } from "react-toastify";
import { connect } from "react-redux";
const ClinicalExamination = ({ getStudentMedicalInfo }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentMedicalInfo(
        token,
        userInstance.studentId,
        userInstance.schoolId,
        "clinical-examination"
      );
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
      console.log(res);
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
    }
  }, [
    getStudentMedicalInfo,
    token,
    userInstance.schoolId,
    userInstance.studentId,
  ]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : bioData ? (
        <PageWrapper>
          <AccordionText>1. General Physical Examination</AccordionText>
          <PageWrapper width="90%">
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">1. General Exmination</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    value={bioData.generalExamination}
                    onChange={(e) => console.log(e.target.value)}
                    placeholder="Clinical Examination Result"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                    disabled={true}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">2. Height</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    disabled={true}
                    type="text"
                    value={`${bioData.height} ft`}
                    placeholder="Height Clinical Result"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">3. Weight</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.weight}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">4. Any major Deformity</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.majorDeformity}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>{" "}
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label
                    htmlFor=""
                    style={{
                      paddingLeft: "18px",
                    }}
                  >
                    Cause Of Deformity
                  </label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.deformityCause}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">5. Visual Acuity </label>
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>{" "}
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label
                    htmlFor=""
                    style={{
                      paddingLeft: "18px",
                    }}
                  >
                    Without glasses
                  </label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InputWrapper pt="0px">
                        <label htmlFor="">Right Eye 6/</label>
                      </InputWrapper>
                      <div
                        style={{
                          width: "40px",
                          marginLeft: "10px",
                        }}
                      >
                        <InputWrapper pt="0px">
                          <input
                            type="number"
                            style={{
                              padding: "2px",
                              textAlign: "center",
                            }}
                            max={10}
                            onChange={(e) => console.log(e.target.value)}
                            disabled={true}
                            value={Number(bioData.withoutGlasses_RightEye)}
                          />
                        </InputWrapper>
                      </div>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InputWrapper pt="0px">
                        <label htmlFor="">Left Eye 6/</label>
                      </InputWrapper>
                      <div
                        style={{
                          width: "40px",
                          marginLeft: "10px",
                        }}
                      >
                        <InputWrapper pt="0px">
                          <input
                            type="number"
                            style={{
                              padding: "2px",
                              textAlign: "center",
                            }}
                            max={10}
                            onChange={(e) => console.log(e.target.value)}
                            disabled={true}
                            value={Number(bioData.withoutGlasses_LeftEye)}
                          />
                        </InputWrapper>
                      </div>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label
                    htmlFor=""
                    style={{
                      paddingLeft: "18px",
                    }}
                  >
                    With glasses
                  </label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InputWrapper pt="0px">
                        <label htmlFor="">Right Eye 6/</label>
                      </InputWrapper>
                      <div
                        style={{
                          width: "40px",
                          marginLeft: "10px",
                        }}
                      >
                        <InputWrapper pt="0px">
                          <input
                            type="number"
                            style={{
                              padding: "2px",
                              textAlign: "center",
                            }}
                            max={10}
                            onChange={(e) => console.log(e.target.value)}
                            disabled={true}
                            value={Number(bioData.withGlasses_RightEye)}
                          />
                        </InputWrapper>
                      </div>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InputWrapper pt="0px">
                        <label htmlFor="">Left Eye 6/</label>
                      </InputWrapper>
                      <div
                        style={{
                          width: "40px",
                          marginLeft: "10px",
                        }}
                      >
                        <InputWrapper pt="0px">
                          <input
                            type="number"
                            style={{
                              padding: "2px",
                              textAlign: "center",
                            }}
                            max={10}
                            onChange={(e) => console.log(e.target.value)}
                            disabled={true}
                            value={Number(bioData.withGlasses_LeftEye)}
                          />
                        </InputWrapper>
                      </div>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup
              style={{
                alignItems: "start",
              }}
            >
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">6. Hearing </label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper pt="0px">
                      <label
                        htmlFor=""
                        style={{
                          paddingLeft: "0px",
                        }}
                      >
                        Left Ear
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper pt="0px">
                      <input
                        type="text"
                        value={bioData.hearing_LeftEar}
                        disabled={true}
                        onChange={(e) => console.log(e.target.value)}
                        placeholder="Please Fill Necessary Information"
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper pt="0px">
                      <label
                        htmlFor=""
                        style={{
                          paddingLeft: "0px",
                        }}
                      >
                        Right Ear
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper pt="0px">
                      <input
                        type="text"
                        value={bioData.hearing_RightEar}
                        disabled={true}
                        onChange={(e) => console.log(e.target.value)}
                        placeholder="Please Fill Necessary Information"
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </PageWrapper>

          <AccordionText>2. Systeminc Examination</AccordionText>
          <PageWrapper width="90%">
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">1. Eyes</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.eyes}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">2. Ears</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.ears}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">3. Mouth</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.mouth}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">4. Teeth</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.teeth}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">5. Skin</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.skin}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">6. Respiratory System</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.respiratorySystem}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">7. Cardiovascular</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.cardiovascular}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>{" "}
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label
                    htmlFor=""
                    style={{
                      paddingLeft: "18px",
                    }}
                  >
                    Blood Pressure
                  </label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.bloodPressure}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label
                    htmlFor=""
                    style={{
                      paddingLeft: "18px",
                    }}
                  >
                    Heart
                  </label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.heart}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">8. Abdomen</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.abdomen}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">9. Central Nervous System</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.centralNervousSystem}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">10. Muscular Skeltal System</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.muscularSkeletalSystem}
                    disabled={true}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </PageWrapper>
          <FormFlex>
            <FormFlexDiv width="100%">
              <InputWrapper>
                <label htmlFor="">
                  Other useful information or observation
                </label>
                <textarea
                  cols="30"
                  rows="3"
                  placeholder="Please Fill Necessary Information"
                  onChange={(e) => console.log(e.target.value)}
                  value={bioData.otherObservation}
                  disabled={true}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
        </PageWrapper>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: colors.primary,
          }}
        >
          No Pathological Medical Data For Student FOund
        </div>
      )}
    </>
  );
};

export default connect(null, { getStudentMedicalInfo })(ClinicalExamination);
