import React, { useState } from "react";
import {
  BigHeader,
  Container,
  SmallHeader,
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "./styles/dashboard.style";
import GlobalStyles from "../../assets001/globalStyle";
import MedicalRecord from "../../components/medical/MedicalRecord";
import DrugUsage from "../../components/medical/DrugUsage";
import InOutRecord from "../../components/medical/InOutRecord";
import Complaints from "../../components/medical/Complaints";
import WelcomeText from "../../components/WelcomeText";

const Medical = () => {
  const [tab, setTab] = useState(1);
  return (
    <Container>
      <GlobalStyles />
      <div style={{ borderBottom: "2px solid #671E78", marginBottom:'40px' }}>
        <WelcomeText />
        {/* <SmallHeader>Hi there! Welcome</SmallHeader> */}
        <BigHeader>Medical</BigHeader>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
   
      </div>

      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            style={{ marginTop: "5px" }}
          >
            <p>Medical Record</p>
          </TabLink>
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            style={{ marginTop: "5px" }}
          >
            <p>Student Drug Usage</p>
          </TabLink>
          <TabLink
            isActive={tab === 3}
            onClick={() => setTab(3)}
            style={{ marginTop: "5px" }}
          >
            <p>Student IN/OUT Record</p>
          </TabLink>
          <TabLink
            isActive={tab === 4}
            onClick={() => setTab(4)}
            style={{ marginTop: "5px" }}
          >
            <p>Medical Complaints Form</p>
          </TabLink>
        </TabLinkContainer>
        {tab === 1 && (
          <TabContent>
            <MedicalRecord />
          </TabContent>
        )}
        {tab === 2 && (
          <TabContent>
            <DrugUsage />
          </TabContent>
        )}

        {tab === 3 && (
          <TabContent>
            <InOutRecord />
          </TabContent>
        )}

        {tab === 4 && (
          <TabContent>
            <Complaints />
          </TabContent>
        )}
      </TabContainer>
    </Container>
  );
};

export default Medical;
