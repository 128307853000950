/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import GlobalStyles from "../../assets001/globalStyle";
import { connect } from "react-redux";
import colors from "../../assets001/colors.json";
import {
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import { InputWrapper, AuthButton } from "../../pages/auth/auth.style";
import Select from "../select/select";
import { useMediaQuery } from "react-responsive";
import { getStudentProfileInfoData } from "../../actions/user";
import { toast } from "react-toastify";
import Loader from "../pageLoader/loader";
const SocialData = ({ getStudentProfileInfoData }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [opt, setOpt] = useState("false");
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [loading, setLoading] = useState(true);
  const [bioData, setBioData] = useState(null);

  const optionsArr = [
    {
      id: 1,
      name: "Yes",
      value: "true",
    },
    {
      id: 2,
      name: "No",
      value: "false",
    },
  ];
  const getData = async () => {
    setLoading(true);
    try {
      let res = await getStudentProfileInfoData(
        token,
        phone,
        userInstance.schoolId,
        "social-data"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.data.Message);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.data.Message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <TabContentWrapper>
      <GlobalStyles />
      {loading ? (
        <Loader />
      ) : bioData ? (
        <>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="position of child">
                  Position of Child In the Family
                </label>
                <input
                  type="text"
                  placeholder="Position of Child in the family"
                  value={bioData.childPosition_In_Family}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>{" "}
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="position of mother">
                  Position of child's mother
                </label>
                <input
                  type="text"
                  placeholder="Position of child's Mother"
                  value={bioData.childPosition_On_Mother}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>

          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="career">
                  Career ambition of your child(list in order of priority)
                </label>
                <textarea
                  disabled={true}
                  cols="30"
                  rows="3"
                  placeholder={bioData.childAmbition}
                  value=""
                  onChange={(e) => console.log(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">What does the child find easy to do</label>
                <textarea
                  disabled={true}
                  cols="30"
                  rows="3"
                  value={bioData.childEasyThings}
                  onChange={(e) => console.log(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="bed wet">Does the child bed wet ?</label>
                <Select
                  title="Bed Wet Status"
                  index="name" // no
                  selected={bioData.isChildBedWet === true ? "true" : "false"} // false
                  options={optionsArr}
                  thirdOption="value" // false
                  disabled={true}
                  setSelected={(val) => {
                    console.log(val);
                    // console.log(val);
                    // setOpt(val.value);
                  }}
                />
              </InputWrapper>{" "}
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="period">
                  Has the child been away from home for long period Before ?
                </label>
                <Select
                  title="Has Child Being Far Away From Home"
                  index="name" // no
                  selected={
                    bioData.isChild_Away_FromHome_Before === true
                      ? "true"
                      : "false"
                  } // false
                  options={optionsArr}
                  thirdOption="value" // false
                  disabled={true}
                  setSelected={(val) => {
                    console.log(val);
                    // console.log(val);
                    // setOpt(val.value);
                  }}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="">
                  Special comment(s) worthy of Note on Child.
                </label>
                <textarea
                  disabled={true}
                  cols="30"
                  rows="3"
                  value={bioData.note_On_Child}
                  onChange={(e) => console.log(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <div
            style={{
              display: "none",
              justifyContent: "end",
            }}
          >
            <AuthButton
              disabled={false}
              width={isMobile ? "100%" : "30%"}
              onSubmit={null}
            >
              <div>
                <span>Update Details</span>
              </div>
              {/* Send Code */}
            </AuthButton>
          </div>
        </>
      ) : (
        <div
          style={{
            fontWeight: "bold",
            color: colors.primary,
            textAlign: "center",
          }}
        >
          No Social Data For Student
        </div>
      )}
    </TabContentWrapper>
  );
};

export default connect(null, { getStudentProfileInfoData })(SocialData);
