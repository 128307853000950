import React, { useState, useEffect, useCallback } from "react";
import { AccountSummaryWrapper } from "../../pages/student-Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets001/colors.json";
import { getStudentMedicalInfo } from "../../actions/user";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../pageLoader/loader";
import moment from "moment";

const DrugUsage = ({ getStudentMedicalInfo }) => {
  const [bioData, setBioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentMedicalInfo(
        token,
        userInstance.studentId,
        userInstance.schoolId,
        "drug-usage"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
    }
  }, [
    getStudentMedicalInfo,
    token,
    userInstance.schoolId,
    userInstance.studentId,
  ]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
            }}
          >
            <tr
              className="table-head"
              style={{
                backgroundColor: "#F0E8F1",
                color: "black",
              }}
            >
              <th>Diagnosis</th>
              <th>Date</th>
              {!isMobile && <th>Doctor/Nurse on Duty</th>}

              <th>Type</th>
              <th>Status</th>
              {!isMobile && <th>Remark</th>}

              <th></th>
            </tr>
            {bioData.length > 0 ? (
              bioData.map((item, index) => (
                <tr
                  key={index}
                  style={{ background: "white", border: "1px solid #F0E8F1" }}
                >
                  <td>{item.diagnosis}</td>
                  <td>{moment(item.date).format("YYYY/MM/DD LT")}</td>

                  <td>{item.doctorNurseOnDuty}</td>
                  {!isMobile && <td>{item.type}</td>}

                  <td>
                    {item.status !== "" && (
                      <p
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          background: colors.secondary,
                          fontWeight: "bold",
                          color: colors.primary,
                        }}
                      >
                        {item.status}
                      </p>
                    )}
                  </td>
                  {!isMobile && <td>{item.remark}</td>}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={!isMobile ? 6 : 4}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  No Drug Usage For This Student
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      )}
    </>
  );
};

export default connect(null, { getStudentMedicalInfo })(DrugUsage);
