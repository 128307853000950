import React from "react";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { IconButton } from "@mui/material";
import { FaFilePdf } from "react-icons/fa";
import { toast } from "react-toastify";

const PdfDownloader = ({ elementId, orientation = "portrait", children }) => {
  const options = {
    // default is `save`
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: orientation,
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },

    overrides: {
      pdf: {
        compress: true,
      },

      canvas: {
        useCORS: true,
      },
    },
  };

  const getTargetElement = () => document.getElementById(elementId);

  return (
    <button
      onClick={() => {
        generatePDF(getTargetElement, options);
        console.log("Clicked on " + elementId);
        toast.success("Please wait. You'll be redirected to download");
      }}
    >
      {children ? (
        children
      ) : (
        <IconButton sx={{ color: "#000" }}>
          <FaFilePdf />
        </IconButton>
      )}
    </button>
  );
};

export default PdfDownloader;
