import { Navigate, Outlet } from "react-router-dom";

const checkAuth = () => {
  const token = localStorage.lasmabAuthToken || "";
  const authStatus = localStorage.lasmabAuthStatus || "";
  const lasmabObj = JSON.parse(localStorage.getItem("lasmabObj")) || {};
  if (token && authStatus && authStatus === "true" && lasmabObj) {
    return true;
  } else {
    return false;
  }
};

// check is
const PublicRoutes = () => {
  const isAuth = checkAuth();

  return isAuth ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoutes;
