/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense, Lazy } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { DashboardNavbar, DashboardSidebar } from "../components/dashboard";
import { Box } from "@mui/system";
import { AppBar } from "@mui/material";
import { connect } from "react-redux";
import { getStudentProfile } from "../actions/user";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/pageLoader/loader";
const Main = styled.div``;
const PageContainer = styled.div``;
const authStatus = localStorage.lasmabAuthStatus;
const LayoutWrapper = styled.div`
  margin-left: 260px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 992px) {
    margin-left: 0;
  }
`;
const SharedLayout = ({ getStudentProfile, token, authStatus }) => {
  const [open, setOpen] = useState(false);
  // const authStatus = Boolean(localStorage.lasmabAuthStatus);

  const location = useLocation();
  // const token = localStorage.lasmabAuthToken || "";
  useEffect(() => {
    if (authStatus) {
      console.log("inside");
      // the user object is in the Reducer user object
      getStudentProfile(token);
    }
  }, [location]);

  return (
    <div>
      <Main className="dashbaord">
        {/* <Drawer/> */}
        <AppBar>
          <DashboardSidebar
            controlNav={() => {
              if (open === true) {
                setOpen(false);
              }
            }}
            isOpen={open}
          />
        </AppBar>
        <LayoutWrapper>
          <DashboardNavbar controlNav={() => setOpen(!open)} />
          <PageContainer>
            <Box sx={{ mt: { lg: 14, md: 10, sm: 10, xs: 10 } }}>
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            </Box>
          </PageContainer>
        </LayoutWrapper>
      </Main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userState.user,
  token: state.userState.token,
  authStatus: state.userState.authStatus,
  pLoading: state.userState.loading,
});

export default connect(mapStateToProps, { getStudentProfile })(SharedLayout);
