import React, { useState, useEffect, useCallback } from "react";
import {
  PageWrapper,
  AccordionText,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { InputWrapper } from "../../pages/auth/auth.style";
import { getStudentMedicalInfo } from "../../actions/user";
import colors from "../../assets001/colors.json";
import Loader from "../pageLoader/loader";
const PathologicalExamination = ({ getStudentMedicalInfo }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentMedicalInfo(
        token,
        userInstance.studentId,
        userInstance.schoolId,
        "pathological-examination"
      );
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
      console.log(res);
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
    }
  }, [
    getStudentMedicalInfo,
    token,
    userInstance.schoolId,
    userInstance.studentId,
  ]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : bioData ? (
        <PageWrapper>
          {/* chest x ray */}
          <InlineLabelGroup
            style={{
              alignItems: "end",
            }}
          >
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="" style={{ fontSize: "20px" }}>
                  1. Chest X-ray
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper pt="0px">
                <input
                  type="text"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                  value={bioData.xray}
                  placeholder="Please Fill Necessary Information"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          {/* Heaf Mantoux */}
          <InlineLabelGroup
            style={{
              alignItems: "end",
            }}
          >
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="" style={{ fontSize: "20px" }}>
                  2. Heaf/ Mantoux Test
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper pt="0px">
                <input
                  type="text"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                  value={bioData.heafMantouxTest}
                  placeholder="Please Fill Necessary Information"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          {/* Blood */}
          <InlineLabelGroup
            style={{
              marginTop: "10px",
            }}
          >
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="" style={{ fontSize: "20px" }}>
                  3. Blood
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <PageWrapper width="90%">
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">1. Full Blood Count</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.fullBloodCount}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">2. HB%</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.hb}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">3. Grouping</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.grouping}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">4. Sickling Test</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.sicklingTest}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">5. Genotype</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.genotype}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </PageWrapper>
          <InlineLabelGroup
            style={{
              marginTop: "10px",
            }}
          >
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="" style={{ fontSize: "20px" }}>
                  4. Urine
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <PageWrapper width="90%">
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">1. Colour, Deposits</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.colourDeposits}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">2. Albunmin</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.albunmin}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">3. Sugar</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.sugar}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">4. Blood Cells</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.bloodCells}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">5. Ova of Protozoa</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.ovaOfProtozoa}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">6. Crystals, Cast</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.crystalsCast}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">7. Organisms</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.organisms}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </PageWrapper>
          <InlineLabelGroup
            style={{
              marginTop: "10px",
            }}
          >
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="" style={{ fontSize: "20px" }}>
                  4. Stool
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <PageWrapper width="90%">
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">1. Macroscopy</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.macroscopy}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">2. Ova or helminths</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.ovaHelminths}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper pt="0px">
                  <label htmlFor="">Remark</label>
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={2}>
                <InputWrapper pt="0px">
                  <input
                    type="text"
                    disabled={true}
                    onChange={(e) => console.log(e.target.value)}
                    value={bioData.remark}
                    placeholder="Please Fill Necessary Information"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </PageWrapper>
          <InlineLabelGroup
            style={{
              marginTop: "20px",
            }}
          >
            <InlineLabelWrapper flex={1}></InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <AccordionText
                style={{
                  textAlign: "center",
                }}
              >
                Approved Medical Officer
              </AccordionText>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="">1. Name</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper pt="0px">
                <input
                  type="text"
                  value={bioData.name}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                  placeholder="Please Fill Necessary Information"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="">2. Address</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper pt="0px">
                <input
                  type="text"
                  value={bioData.address}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                  placeholder="Please Fill Necessary Information"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px">
                <label htmlFor="">3. Signature Date</label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={2}>
              <InputWrapper pt="0px">
                <input
                  type="text"
                  value={bioData.signatureDate}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                  placeholder="Please Fill Necessary Information"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </PageWrapper>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: colors.primary,
          }}
        >
          No Pathological Medical Data For Student FOund
        </div>
      )}
    </>
  );
};

export default connect(null, { getStudentMedicalInfo })(
  PathologicalExamination
);
