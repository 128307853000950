export default function ArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      className="feather feather-chevron-up"
    >
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  );
}
