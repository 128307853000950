import React, { useEffect, useState } from "react";
import {
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import parse from "html-react-parser";
import {
  AuthButton,
  ButtonFlex,
  InputWrapper,
} from "../../pages/auth/auth.style";
import colors from "../../assets001/colors.json";
import { useMediaQuery } from "react-responsive";
import { InsertEmoticonRounded } from "@mui/icons-material";
import PdfDownloader from "../PdfDownloader";

const Question = ({ name, obj, questions }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  console.log("ttttqqq", questions);
  console.log("heere ooo", questions);
  return (
    <TabContentWrapper>
      <div style={{ marginTop: "5px" }} id="classwork">
        <FormFlex>
          <FormFlexDiv width="100%">
            <InputWrapper>
              <label htmlFor="">Title</label>
              <input
                type="text"
                value={obj.title}
                onChange={(e) => console.log(e.target.value)}
              />
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>

        <FormFlex>
          <FormFlexDiv width="100%">
            <InputWrapper>
              <label htmlFor="">Description</label>
              <textarea
                // cols="30"
                // rows="3"
                value={obj.description}
                onChange={(e) => console.log(e.target.value)}
              ></textarea>
            </InputWrapper>
          </FormFlexDiv>
        </FormFlex>
        {Array.isArray(questions) && questions.length > 0 && (
          <FormFlex>
            <FormFlexDiv width="100%">
              <InputWrapper>
                <label htmlFor="">Questions</label>
                <div
                  style={{
                    border: `1px solid ${colors.primary}`,
                    borderRadius: "5px",
                    padding: "10px",
                    marginTop: "9px",
                  }}
                >
                  {questions.map((item, index) => (
                    <div
                      key={index}
                      style={{ borderBottom: `1px solid ${colors.lightGray}` }}
                    >
                      <div
                        style={{
                          margin: "10px 0px",

                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {index + 1}. {parse(item.question)}
                      </div>
                      {name === "Classwork"
                        ? item.donloadFilePath &&
                          item.donloadFilePath.length > 0 &&
                          item.donloadFilePath.startsWith(
                            "https://res.cloudinary.com"
                          ) && (
                            <img
                              src={item.donloadFilePath}
                              alt="image"
                              style={{
                                objectFit: "contain",
                                maxHeight: "200px",
                                maxWidth: "80%",
                              }}
                            />
                          )
                        : item.downloadFilePath &&
                          item.downloadFilePath.length > 0 &&
                          item.downloadFilePath.startsWith(
                            "https://res.cloudinary.com"
                          ) && (
                            <img
                              src={item.downloadFilePath}
                              alt="image"
                              style={{
                                objectFit: "contain",
                                maxHeight: "200px",
                                maxWidth: "80%",
                              }}
                            />
                          )}
                    </div>
                  ))}
                </div>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
        )}
        {/* {questions.length > 0 && (
          <>
            <div> {parse(questions[0].question)}</div>
            <FormFlex>
              <FormFlexDiv width="100%">
                <InputWrapper>
                  <label htmlFor="">Question 1</label>
                  <textarea
                    // cols="30"
                    // rows="3"
                    value={parse(questions[0].question)}
                    onChange={(e) => console.log(e.target.value)}
                  ></textarea>
                </InputWrapper>
              </FormFlexDiv>
            </FormFlex>
          </>
        )} */}

        <ButtonFlex style={{ justifyContent: "end" }}>
          <PdfDownloader
            elementId={"classwork"}
            children={
              <AuthButton
                width={isMobile ? "100%" : "40%"}
                style={{
                  marginLeft: isMobile ? "0px" : "5px",
                }}
              >
                Download {name}
              </AuthButton>
            }
          />
        </ButtonFlex>
      </div>
    </TabContentWrapper>
  );
};

export default Question;
