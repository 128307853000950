import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Drawer, IconButton, List, Button } from "@mui/material";
import styledd from "styled-components";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { BsSearch } from "react-icons/bs";
import logo from "../../assets001/school-logo.png";
import { SidebarData } from "../../assets001/Data/SidebarData";
import { createGlobalStyle } from "styled-components";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutAction } from "../../actions/user";
import { connect } from "react-redux";
import ChangePin from "./ChangePin";
import UserGuideButton from "../UserGuide/UserGuideButton";
const LogoContainer = styledd.div`
  height: 85px;
  margin-top: 50px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

`;
const Logo = styledd.img`
  object-fit: cover;
  width: 80px;
  margin-left: 4px;
`;
const GlobalStyle = createGlobalStyle`
.p{
  font-family:Gilroy-Regular;
  font-size:14px;
  color:#ffffff;
  opacity:0.4;
  margin-left:40px;
}

a{
  color:white;
  font-family:Gilroy-Regular;
  text-decoration:none;
  opacity:0.4;
  &:hover {
    color:white;
    opacity:1;
  }
}
`;

const SidebarWrapper = styledd.div`
  width: 260px;
  background: #671e78;

  position: fixed;
  animation: sidebar-slide-right 0.8s;
overflow-y:scroll;
padding-bottom:20px;

  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1020;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const NavContainer = styledd.div`
  width: 100%;
  margin-top: 40px;
 
`;
const Tab = styledd.div`
  margin-top: 30px;
`;
const Tabs = styledd.nav`
width:94%;
display:flex;
align-items:center;
padding:10px 40px;
margin-bottom: 5px;
&:hover{
  background-color: #D9D9D933;
  border-left:4px solid #fff;
  border-radius:0px 3px 3px 0px;
},
`;
const Icon = styledd.span`
  margin-right: 15px;
`;
const Buttonn = styledd.button`
  padding: 10px 30px;
  font-family: Gilroy-Regular;
  color: #671e78;
  margin-top: 40px;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 3px;
  margin-left: 40px;
`;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Drawers = ({ logoutAction }) => {
  const navigate = useNavigate();
  const logout = async () => {
    console.log("Logout");
    logoutAction();
    localStorage.removeItem("lasmabAuthStatus");

    navigate("/");
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List
          sx={{
            background: "#691e78",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <LogoContainer>
              <Logo src={logo} />
            </LogoContainer>
          </Link>

          <SidebarWrapper>
            <GlobalStyle />
            <LogoContainer>
              <Logo src={logo} />
            </LogoContainer>
            <NavContainer>
              <p className="p">MENU</p>
              <Tab>
                {SidebarData.map((data, index) => (
                  // <>
                  <NavLink
                    to={data.link}
                    key={index}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <Tabs key={data.id}>
                      <Icon>{data.icon}</Icon>
                      {data.label}
                    </Tabs>
                  </NavLink>
                  // </>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "25px",
                  }}
                >
                  <ChangePin color="white" />
                </Box>

                {/* <Buttonn>Parent Dashboard</Buttonn> */}
                <Box sx={{ pl: 3, mt: 4 }}>
                  <Button
                    variant=""
                    sx={{ color: "#fff", fontFamily: "Gilroy-regular" }}
                    onClick={logout}
                  >
                    <LogoutIcon sx={{ mr: 2 }} /> Logout
                  </Button>
                </Box>
              </Tab>
            </NavContainer>
          </SidebarWrapper>
        </List>
      </Drawer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 5,
          pb: 3,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: 3,
          }}
        >
          <Avatar src="https://res.cloudinary.com/dgkcytiv6/image/upload/v1693005681/qkgkhvblmekt5uxj4w30_eucaxm.jpg" />

          <UserGuideButton />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "15%",
            columnGap: 3,
          }}
        >
          <Badge badgeContent={2} color="error">
            <NotificationsNoneIcon />
          </Badge>
          <IconButton
            sx={{ color: "#000" }}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon />
          </IconButton>

          {/* <MenuIcon style={{ fontSize: "25px", cursor:'pointer' }} /> */}
        </Box>
      </Box>
    </>
  );
};

export default connect(null, { logoutAction })(Drawers);
