/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";

import OutsideClickHandler from "react-outside-click-handler";

import {
  SelectWrapper,
  SelectedOptionWrapper,
  SelectLists,
  SelectList,
} from "./select.style";

export default function Select({
  selected,
  options,
  setSelected,
  index,
  thirdOption,
  title,
  onClick,
  disabled,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {}, [open]);
  return (
    <SelectWrapper
      className={disabled && "disabled"}
      style={{
        pointerEvents: disabled ? "none" : "",
      }}
    >
      <SelectedOptionWrapper>
        {selected ? (
          options
            .filter(
              (option) =>
                option.id == selected ||
                option[index] == selected ||
                option[thirdOption] == selected
            )
            .map((item, i) => <p key={i}>{item[index]}</p>)
        ) : (
          <span>Select {title}</span>
        )}
      </SelectedOptionWrapper>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <SelectLists hide={open}>
          {options &&
            options.map((item) => {
              let active = false;
              if (selected && selected[index] === item[index]) {
                active = true;
              }
              return (
                <SelectList
                  onClick={() => {
                    setSelected(item);
                    setOpen(true);
                    if (onClick) {
                      onClick();
                    }
                  }}
                  key={item[index]}
                  active={active}
                >
                  {item[index]}
                </SelectList>
              );
            })}
        </SelectLists>
      </OutsideClickHandler>
    </SelectWrapper>
  );
}
