/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import Question from "./Question";
import Submission from "./Submission";
import colors from "../../assets001/colors.json";
import Comments from "./Comments";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Loader from "../pageLoader/loader";
import {
  getSingleClasswork,
  submitClassworkAnswer,
  submitClassworkComment,
  getClassworkComments,
  uploadFileAnswer,
  getClassworkQuestions,
} from "../../actions/user";
import { AuthButton } from "../../pages/auth/auth.style";
import { useMediaQuery } from "react-responsive";
import { IoReturnUpBack } from "react-icons/io5";
import api from "../../api/user";

const ClassworkChild = ({
  getSingleClasswork,
  submitClassworkAnswer,
  submitClassworkComment,
  uploadFileAnswer,
  getClassworkComments,
  getClassworkQuestions,
}) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [qLoading, setQLoading] = useState(true);
  const { slug } = useParams();
  const [formLoading, setFormLoading] = useState(false);
  const [trails, setTrails] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [comment, setComment] = useState("");
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [actionType, setActionType] = useState("");
  const [fileObj, setFileObj] = useState({
    name: "",
    content: "",
  });
  const [classworks, setClassworks] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const goBack = () => {
    navigate("/classwork");
  };
  const getAllClasswork = async () => {
    setLoading(true);
    try {
      let res = await api.getClassworkList(
        token,
        userInstance.studentId,
        userInstance.schoolId
      );
      if (res.status === true && res.statusCode === 2) {
        let allClassworks = [];
        if (res.data?.pendingClassworkList) {
          allClassworks = [...allClassworks, ...res.data.pendingClassworkList];
        }
        if (res.data?.attenptedClassworkList) {
          allClassworks = [
            ...allClassworks,
            ...res.data.attenptedClassworkList,
          ];
        }
        setClassworks(allClassworks);
      } else {
        setError(res?.data?.Message || "Failed to fetch classwork");
      }
    } catch (err) {
      setError("Error fetching classwork");
      console.error("Error fetching classwork:", err);
    } finally {
      setLoading(false);
    }
  };
  const getData = async () => {
    setTab(1);
    try {
      let res = await getSingleClasswork(
        token,
        slug,
        userInstance.schoolId,
        userInstance.studentId
      );
      if (res.status === true && res.statusCode === 2) {
        setPageData(res.data);
        setTimeout(setLoading(false), 6000);
      } else {
        toast.error("An Error Occurred Loading Classwork Details");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("An Error Occurred Loading Classwork Details");
    }
  };
  const getComments = async () => {
    try {
      let res = await getClassworkComments(token, slug);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setTrails(res.data);
        setLoading(false);
      } else {
        setTrails([]);
        // toast.error("Please Try Again");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // toast.error("Please Try Again");
    }
  };
  const commentFunc = async () => {
    try {
      let res = await submitClassworkComment(
        token,
        comment,
        slug,
        userInstance.studentId,
        "S"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message);
        // setAnswer("");
        setComment("");
        console.log(comment);
        getComments();
        console.log("it's true");
      } else {
        setFormLoading(false);
        toast.error(res.data.Message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An Erro Occurred");
    }
  };

  const getQuestions = async () => {
    try {
      setQuestion(null);
      setQLoading(true);
      let res = await getClassworkQuestions(
        token,
        slug,
        userInstance.studentId
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setQuestions(res.data);
        setQLoading(false);
      } else {
        toast.error(
          "Questions Has Not Been Uploaded, Please Check Back Later..."
        );
        setQLoading(false);
      }
    } catch (error) {
      console.log(error);
      setQLoading(false);
      toast.error(
        "Questions Has Not Been Uploaded, Please Check Back Later..."
      );
    }
  };

  const sortTrailsByDate = (trails) => {
    return trails.sort(
      (a, b) => new Date(a.commentDate) - new Date(b.commentDate)
    );
  };

  const sortedTrails = sortTrailsByDate([...trails]);

  useEffect(() => {
    getData();
    getComments();
    getQuestions();
    getAllClasswork();
  }, []);

  const submitFunc = async (type) => {
    setActionType(type);
    setFormLoading(true);
    try {
      let res = await submitClassworkAnswer(
        token,
        question.submittedAnswer,
        slug,
        userInstance.studentId,
        question.questionId,
        type
      );

      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        console.log("it's true");
        toast.success(res.message);
        getQuestions();

        setFormLoading(false);
      } else {
        setFormLoading(false);
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };

  const uploadAnswer = async () => {
    setFormLoading(true);
    try {
      let res = await uploadFileAnswer(
        token,
        Number(userInstance.studentId),
        Number(userInstance.schoolId),
        Number(userInstance.classId),
        fileObj.name,
        fileObj.content,
        Number(slug),
        "C"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message);
        setFormLoading(false);
        getQuestions();
        getData();

        console.log("it's true");
        setFileObj({ ...fileObj, name: "", content: "" });
      } else {
        setFormLoading(false);
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };
  return (
    <TabContainer style={{ paddingBottom: "0px" }}>
      <AuthButton
        color={colors.primary}
        bg="white"
        width={isMobile ? "100%" : "10%"}
        onClick={goBack}
        className="m-6 flex items-center gap-2 justify-center"
      >
        <IoReturnUpBack className="text-[#671e78] w-6 h-6 " />
        Back
      </AuthButton>
      <TabLinkContainer>
        <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
          Classwork
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
          Attempt And Submit Classwork
        </TabLink>
        <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
          Comments
        </TabLink>
      </TabLinkContainer>
      {loading ? (
        <Loader />
      ) : pageData !== null ? (
        <>
          {tab === 1 && (
            <TabContent>
              <Question name="Classwork" obj={pageData} questions={questions} />
            </TabContent>
          )}
          {tab === 2 && (
            <TabContent>
              <Submission
                actionType={actionType}
                loading={qLoading}
                questions={questions}
                question={question}
                setQuestion={setQuestion}
                callSubmit={submitFunc}
                formLoading={formLoading}
                fileObj={fileObj}
                setFileObj={setFileObj}
                uploadAnswerFunc={uploadAnswer}
                classworks={classworks}
              />
            </TabContent>
          )}
          {tab === 3 && (
            <TabContent>
              <Comments
                studentId={userInstance.studentId}
                trails={sortedTrails}
                message={comment}
                setMessage={setComment}
                pushComment={commentFunc}
              />
            </TabContent>
          )}
        </>
      ) : (
        <div>
          <p
            style={{
              textAlign: "center",
              color: colors.primary,
              fontWeight: "bold",
            }}
          >
            Nothing to show for now
          </p>
        </div>
      )}
    </TabContainer>
  );
};

export default connect(null, {
  getSingleClasswork,
  submitClassworkAnswer,
  submitClassworkComment,
  getClassworkComments,
  uploadFileAnswer,
  getClassworkQuestions,
})(ClassworkChild);
