import React from "react";
import {
  DateIconWrapper,
  DateInputWrapper,
  DatePickerButton,
  DatePickerSelect,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import DatePicker from "react-datepicker";
import Calendar from "../../assets001/icons/Calendar";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import ChevronLeft from "../../assets001/icons/chevron-left";
import ChevronRight from "../../assets001/icons/chevron-right";

const DatePickerComponent = ({
  selected,
  setSelected,
  color,
  bg,
  useBorder,
  disabled,
}) => {
  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };
  const years = range(2002, getYear(new Date()) + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <DateInputWrapper color={color} bg={bg}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <DatePickerButton
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ChevronLeft />
              </DatePickerButton>

              <DatePickerSelect
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
                style={{
                  height: "30px",
                  marginTop: 0,
                  marginRight: "2px",
                }}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </DatePickerSelect>

              <DatePickerSelect
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
                style={{
                  height: "30px",
                  marginTop: 0,
                  marginRight: "2px",
                }}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </DatePickerSelect>

              <DatePickerButton
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {" "}
                <ChevronRight />
              </DatePickerButton>
            </div>
          )}
          onChangeRaw={(e) => e.preventDefault()}
          selected={selected}
          onChange={(date) => setSelected(date)}
          disabled={disabled ? true : false}
        />
        <DateIconWrapper useBorder={useBorder}>
          <Calendar />
        </DateIconWrapper>
      </DateInputWrapper>
    </>
  );
};

export default DatePickerComponent;
