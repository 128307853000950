export default function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: "2px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      className="feather feather-arrow-right-circle"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <polyline points="12 16 16 12 12 8"></polyline>
      <line x1="8" y1="12" x2="16" y2="12"></line>
    </svg>
  );
}
