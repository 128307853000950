import React from "react";
// import styled from "styled-components";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import WelcomeText from "../../components/WelcomeText";
import { styled } from "@mui/material/styles";

// import { Link } from "react-router-dom";
// import SearchIcon from '@mui/icons-material/Search';

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
    fontFamily: "gilroy-regular",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));
function createData(date, subject, title, postedBy, Duration, Score) {
  return { date, subject, title, postedBy, Duration, Score };
}

const rows = [
  createData(
    "2021/2022",
    "Mathematics",
    "First subject",
    "Mrs Idowu ",
    "20 mins",
    40
  ),
  createData(
    "2021/2022",
    "Agricultural Science",
    "First subject",
    "Mr Johnson",
    "50 mins ",
    50
  ),
  createData(
    "2021/2022",
    "Yoruba",
    "First subject",
    "Mr Ogunlakin ",
    "50 mins ",
    70
  ),
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Bold",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const Cbt = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          <Box sx={{ borderBottom: "2px solid #671E78" }}>
            <WelcomeText />
            <Typography
              variant="h1"
              sx={{
                mt: 3,
                fontSize: {
                  lg: "42px",
                  md: "42px",
                  sm: "38px",
                  xs: "30px",
                },
              }}
            >
              CBT
            </Typography>
          </Box>
          {/* .......,,,,,,,,,,,,,,.............Tables..............,,,,,,,,,,,,,,,,............... */}
          <Box
            mt={5}
            pb={3}
            sx={{
              border: "1px solid #671E78",
              borderRadius: "20px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  borderRadius: "20px 20px 0px 0px",
                  background: "rgba(0, 0, 0, 0.12)",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Tabs
                  value={value}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Practice"
                    {...a11yProps(0)}
                    sx={{
                      width: "35%",
                      fontFamily: "gilroy-bold",
                      color: "#000",
                    }}
                  />
                  <Tab
                    label="Test"
                    {...a11yProps(1)}
                    sx={{
                      width: "35%",
                      fontFamily: "gilroy-bold",
                      color: "#000",
                    }}
                  />
                  <Tab
                    label="Exam"
                    {...a11yProps(2)}
                    sx={{
                      width: "35%",
                      fontFamily: "gilroy-bold",
                      color: "#000",
                    }}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}
                sx={{ p: 0, fontFamily: "Gilroy-Regular" }}
              >
                <TableContainer sx={{ width: "100%", p: 0 }}>
                  <Table
                    sx={{ minWidth: 700, p: 0, fontFamily: "Gilroy-Regular" }}
                    aria-label="customized table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "rgba(103, 30, 120, 0.1)",
                        width: "100%",
                      }}
                    >
                      <TableRow sx={{ p: 0 }}>
                        <StyledTableCell>Posted Date</StyledTableCell>
                        <StyledTableCell>Subject</StyledTableCell>
                        <StyledTableCell>Tittle</StyledTableCell>
                        <StyledTableCell>Posted By</StyledTableCell>
                        <StyledTableCell>Duration</StyledTableCell>
                        <StyledTableCell>My Score</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                      {rows.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {row.date}
                          </StyledTableCell>
                          <StyledTableCell>{row.subject}</StyledTableCell>
                          <StyledTableCell>{row.title}</StyledTableCell>
                          <StyledTableCell>{row.postedBy}</StyledTableCell>
                          <StyledTableCell>{row.Duration}</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: row.Duration <= "40" ? "red" : "#000",
                            }}
                          >
                            {row.Score}%
                          </StyledTableCell>

                          <StyledTableCell>
                            <Link to="/quiz">
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: "100px",
                                  fontFamily: "gilroy-regular",
                                  px: 4,
                                }}
                              >
                                Start
                              </Button>
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableContainer sx={{ width: "100%", p: 0 }}>
                  <Table
                    sx={{ minWidth: 700, p: 0 }}
                    aria-label="customized table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "rgba(103, 30, 120, 0.1)",
                        width: "100%",
                      }}
                    >
                      <TableRow sx={{ p: 0 }}>
                        <StyledTableCell>Posted Date</StyledTableCell>
                        <StyledTableCell>Subject</StyledTableCell>
                        <StyledTableCell>Tittle</StyledTableCell>
                        <StyledTableCell>Posted By</StyledTableCell>
                        <StyledTableCell>Duration</StyledTableCell>
                        <StyledTableCell>My Score</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                      {rows.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {row.date}
                          </StyledTableCell>
                          <StyledTableCell>{row.subject}</StyledTableCell>
                          <StyledTableCell>{row.title}</StyledTableCell>
                          <StyledTableCell>{row.postedBy}</StyledTableCell>
                          <StyledTableCell>{row.Duration}</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: row.Duration <= "40" ? "red" : "#000",
                            }}
                          >
                            {row.Score}%
                          </StyledTableCell>

                          <StyledTableCell>
                            <Link to="/quiz">
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: "100px",
                                  fontFamily: "gilroy-regular",
                                  px: 4,
                                }}
                              >
                                Start
                              </Button>
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TableContainer sx={{ width: "100%", p: 0 }}>
                  <Table
                    sx={{ minWidth: 700, p: 0 }}
                    aria-label="customized table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "rgba(103, 30, 120, 0.1)",
                        width: "100%",
                      }}
                    >
                      <TableRow sx={{ p: 0 }}>
                        <StyledTableCell>Posted Date</StyledTableCell>
                        <StyledTableCell>Subject</StyledTableCell>
                        <StyledTableCell>Tittle</StyledTableCell>
                        <StyledTableCell>Posted By</StyledTableCell>
                        <StyledTableCell>Duration</StyledTableCell>
                        <StyledTableCell>My Score</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                      {rows.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {row.date}
                          </StyledTableCell>
                          <StyledTableCell>{row.subject}</StyledTableCell>
                          <StyledTableCell>{row.title}</StyledTableCell>
                          <StyledTableCell>{row.postedBy}</StyledTableCell>
                          <StyledTableCell>{row.Duration}</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: row.Duration <= "40" ? "red" : "#000",
                            }}
                          >
                            {row.Score}%
                          </StyledTableCell>

                          <StyledTableCell>
                            <Link to="/quiz">
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: "100px",
                                  fontFamily: "gilroy-regular",
                                  px: 4,
                                }}
                              >
                                Start
                              </Button>
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Cbt;
