/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  TabContainer,
  TabLinkContainer,
  TabLink,
  TabContent,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import Question from "./Question";
import Submission from "./Submission";
import Comments from "./Comments";
import colors from "../../assets001/colors.json";
import { connect } from "react-redux";
import {
  getSingleAssignment,
  submitAssignmentAnswer,
  submitAssignmentComment,
  getAssignmentComments,
  getAssignmentQuestions,
  uploadFileAnswer,
} from "../../actions/user";
import Loader from "../pageLoader/loader";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { AuthButton } from "../../pages/auth/auth.style";
import { useMediaQuery } from "react-responsive";
import { IoReturnUpBack } from "react-icons/io5";
import AssignmentSubmission from "./AssignmentSubmission";
import { getAssignmentList } from "../../actions/user";

const AssignmentChild = ({
  getSingleAssignment,
  submitAssignmentAnswer,
  submitAssignmentComment,
  getAssignmentComments,
  uploadFileAnswer,
  getAssignmentQuestions,
}) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";
  const [actionType, setActionType] = useState("");
  // const accountType = localStorage.lasmabAccountType || "";
  const [pageData, setPageData] = useState(null);
  const [tab, setTab] = useState(1);
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [qLoading, setQLoading] = useState(true);
  const [trails, setTrails] = useState([]);
  const [comment, setComment] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [fileObj, setFileObj] = useState({
    name: "",
    content: "",
  });

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const goBack = () => {
    navigate("/assignment");
  };

  const getData = async () => {
    setLoading(true);
    setTab(1);
    try {
      let res = await getSingleAssignment(
        token,
        slug,
        userInstance.schoolId,
        userInstance.studentId
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setPageData(res.data);
        setLoading(false);
      } else {
        toast.error("An Error Occurred Loading Classwork Details");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("An Error Occurred Loading Classwork Details");
    }
  };

  const getQuestions = async () => {
    setQLoading(true);
    try {
      let res = await getAssignmentQuestions(
        token,
        slug,
        userInstance.studentId
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setQuestions(res.data);
        setQLoading(false);
      } else {
        toast.error(
          "Questions Has Not Been Uploaded, Please Check Back Later..."
        );
        setQLoading(false);
      }
    } catch (error) {
      console.log(error);
      setQLoading(false);
      toast.error(
        "Questions Has Not Been Uploaded, Please Check Back Later..."
      );
    }
  };
  const getComments = async () => {
    try {
      let res = await getAssignmentComments(token, slug);
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setTrails(res.data);
        setLoading(false);
      } else {
        // setTrails([]);
        // toast.error("Please Try Again");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      // toast.error("Please Try Again");
    }
  };

  const getAllAssignments = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getAssignmentList(
        token,
        userInstance.studentId,
        userInstance.schoolId
      )();
      if (res.status === true && res.statusCode === 2) {
        let allAssignments = [];
        if (res.data?.pendingAssignments) {
          allAssignments = [...allAssignments, ...res.data.pendingAssignments];
        }
        if (res.data?.attemptedAssignments) {
          allAssignments = [
            ...allAssignments,
            ...res.data.attemptedAssignments,
          ];
        }

        setAssignments(allAssignments);
      } else {
        toast.error(res.data.message || "failed to fetch assignments");

        console.log("something is wrong");
      }
    } catch (err) {
      console.log(err);
      // toast.error(err.data.message);
    } finally {
      setLoading(false);
    }
  }, [token, userInstance]);

  useEffect(() => {
    // if (pageData === null) {
    getData();
    getComments();
    getQuestions();
    getAllAssignments();
    // }
  }, []);
  const commentFunc = async () => {
    try {
      setFormLoading(true);
      let res = await submitAssignmentComment(
        token,
        comment,
        slug,
        userInstance.studentId,
        "S"
      );
      console.log(res);
      console.log("Changes");

      if (res.status === true) {
        console.log("it's true");
        toast.success(res.message);
        setComment("");
        getComments();
        console.log("it's true");
      } else {
        setFormLoading(false);
        toast.error(res.data.Message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An Erro Occurred");
    }
  };
  // const showQuestion = (id) => {
  //   setAnswer("");
  //   console.log(id);
  //   let obj = questions.find((item) => item.questionId === id);
  //   console.log(obj);
  //   setQuestion(obj);
  // };

  const submitFunc = async (type) => {
    setActionType(type);
    setFormLoading(true);
    try {
      let res = await submitAssignmentAnswer(
        token,
        question.submittedAnswer,
        slug,
        userInstance.studentId,
        question.questionId,
        type
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message);
        getQuestions();
        setFormLoading(false);
        console.log("it's true");
      } else {
        setFormLoading(false);
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };
  const uploadAnswer = async () => {
    setFormLoading(true);
    try {
      let res = await uploadFileAnswer(
        token,
        Number(userInstance.studentId),
        Number(userInstance.schoolId),
        Number(userInstance.classId),
        fileObj.name,
        fileObj.content,
        Number(slug),
        "A"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        toast.success(res.message);
        setFormLoading(false);
        getQuestions();
        getData();

        setFileObj({ ...fileObj, name: "", content: "" });
        console.log("it's true");
      } else {
        setFormLoading(false);
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };
  return (
    <TabContainer>
      <AuthButton
        color={colors.primary}
        bg="white"
        width={isMobile ? "100%" : "10%"}
        onClick={goBack}
        className="m-6 flex items-center gap-2 justify-center"
      >
        <IoReturnUpBack className="text-[#671e78] w-6 h-6 " />
        Back
      </AuthButton>
      <TabLinkContainer>
        <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
          Assignment
        </TabLink>
        <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
          Attempt And Submit Assignment
        </TabLink>
        <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
          Comments
        </TabLink>
      </TabLinkContainer>
      {loading ? (
        <Loader />
      ) : pageData !== null ? (
        <>
          {tab === 1 && (
            <TabContent>
              <Question
                goBack={goBack}
                name="Assignment"
                obj={pageData}
                questions={questions}
              />
            </TabContent>
          )}
          {tab === 2 && (
            <TabContent>
              <AssignmentSubmission
                actionType={actionType}
                goBack={goBack}
                loading={qLoading}
                questions={questions}
                question={question}
                setQuestion={setQuestion}
                // findQuestion={showQuestion}
                callSubmit={submitFunc}
                formLoading={formLoading}
                fileObj={fileObj}
                setFileObj={setFileObj}
                uploadAnswerFunc={uploadAnswer}
                assignments={assignments}
              />
            </TabContent>
          )}
          {tab === 3 && (
            <TabContent>
              <Comments
                formLoading={formLoading}
                goBack={goBack}
                studentId={userInstance.studentId}
                trails={trails}
                message={comment}
                setMessage={setComment}
                pushComment={commentFunc}
              />
            </TabContent>
          )}
        </>
      ) : (
        <div>
          <p
            style={{
              fontWeight: "bold",
              color: colors.primary,
              textAlign: "center",
            }}
          >
            Nothing to show for now
          </p>
        </div>
      )}
    </TabContainer>
  );
};

export default connect(null, {
  getSingleAssignment,
  submitAssignmentAnswer,
  submitAssignmentComment,
  getAssignmentComments,
  getAssignmentQuestions,
  uploadFileAnswer,
})(AssignmentChild);
