import React, { useState, useEffect } from "react";
import {
  Input,
  Search,

} from "../../pages/student-Dashboard/styles/dashboard.style";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography, Button, Link } from "@mui/material";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import moment from "moment";
import Pagination from "../pagination_one/pagination";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
// import { Link } from "react-router-dom";
import { getTutorial } from "../../actions/user";
import { connect } from "react-redux";
import Loader from "../pageLoader/loader";
import colors from "../../assets001/colors.json";
import { ButtonFlex } from "../../pages/auth/auth.style";
import { useMediaQuery } from "react-responsive";
// import Link from "@mui/material/Link";
// import SearchIcon from '@mui/icons-material/Search';
let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});
// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   backgroundColor: "#FFF",
//   width: "645px",
//   border: "1px solid #691e78",
//   borderRadius: "100px",
//   "&:hover": {
//     // backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "445px",

//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//   },
// }));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  fontFamily: "Gilroy-Regular",
  fontSize:'13px',
  paddingRight:'10px',
  color:'#671e78',
  opacity:1,
  width:'500px',
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "120%",

    [theme.breakpoints.up("md")]: {
      width: "60ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));
function createData(date, subject, resource, teacher) {
  return { date, subject, resource, teacher };
}
const rows = [
  createData(
    "2021/2022",
    "wfrgfbfbkkkkkk",
    "First subject",
    "Lorem ipsum dolor sit amet, consndum nulla. Ultrices mattis faucibus tortor, tellus sed. Ipsum ",
    "Lorem ipsum dolor sit amet, consndum nulla. Ultrices mattis faucibus tortor, tellus sed. Ipsum "
  ),
  createData(
    "2021/2022",
    "wfrgfbfbkkkkkk",
    "First subject",
    "Lorem ipsum dolor sit amet, consndum nulla. Ultrices mattis faucibus tortor, tellus sed. Ipsum ",
    "Lorem ipsum dolor sit amet, consndum nulla. Ultrices mattis faucibus tortor, tellus sed. Ipsum "
  ),
];

const TutorialFirst = ({ getTutorial }) => {
  const isTab = useMediaQuery({ maxWidth: 992 });
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [allData, setAllData] = useState([]);
  const [arrData, setArrData] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sQuery, setSQuery] = useState("");
  useEffect(() => {
    if (allData.length > 0) {
      const endOffSet = itemOffSet + itemsPerPage;
      const newOffSet = (currentPage * itemsPerPage) % allData.length;
      const trimStart = (currentPage - 1) * itemsPerPage;
      const trimEnd = trimStart + itemsPerPage;
      const arr = allData.slice(trimStart, trimEnd);
      setArrData(arr);
      setitemOffSet(newOffSet);
    }
    setPageCount(Math.ceil(allData.length / itemsPerPage));
  }, [allData, currentPage]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      let res = await getTutorial(
        token,
        userInstance.classId,
        userInstance.schoolId
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setAllData(res.data);
        setLoading(false);
      } else {
        toast.error(res.data.Message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err.data.Message);
    }
  };

  const filterSearch = async (e) => {
    console.log(e.target.value);
    if (e.target.value === "") {
      getData();
    } else {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).forEach((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArrData(arr);
      setPageCount(0);
    }
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          {/* .......,,,,,,,,,,,,,,.............Tables..............,,,,,,,,,,,,,,,,............... */}
          <Box
            mt={5}
            pb={3}
            sx={{
              border: "1px solid #671E78",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                borderRadius: "20px 20px 0px 0px",
                pt: 4,
                background: "rgba(103, 30, 120, 0.1)",
                height: "100px",
                pl: { xs: 2.5 },
              }}
            >
              <Box sx={{width:'80%'}}>
                   <Search
              value={sQuery}
              type="search"
              placeholder="Date(e.g 2021-05-08), Subject, Title & Subject Teacher"
              onChange={(e) => {
                // console.log(e.target.value);
                setSQuery(e.target.value);
                filterSearch(e);
              }}
            />
            </Box>
              {/* <Search
                sx={{
                  width: {
                    lg: "645px",
                    md: "330px",
                    sm: "auto",
                    xs: "auto",
                    pl: { xs: 6 },
                    
                  },
                }}
              >
                <SearchIconWrapper>
                  <BsSearch />
                </SearchIconWrapper>
                <StyledInputBase
                  value={sQuery}
                  placeholder="Date(e.g 2021-05-08), Subject, Title & Teacher"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    filterSearch(e);
                    setSQuery(e.target.value);
                  }}
                />
              </Search> */}
            </Box>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    sx={{
                      backgroundColor: "rgba(103, 30, 120, 0.1)",
                      width: "100%",
                    }}
                  >
                    <TableRow>
                      {!isTab && <StyledTableCell>Date</StyledTableCell>}

                      <StyledTableCell>Subject</StyledTableCell>
                      <StyledTableCell>Resource Title</StyledTableCell>
                      {!isTab && (
                        <StyledTableCell>Subject Teacher</StyledTableCell>
                      )}

                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                    {arrData.length > 0 ? (
                      arrData.map((row, index) => (
                        <StyledTableRow key={index}>
                          {!isTab && (
                            <StyledTableCell component="th" scope="row">
                              {moment(row.date).format("YYYY/MM/DD")}
                            </StyledTableCell>
                          )}

                          <StyledTableCell>{row.subject}</StyledTableCell>
                          <StyledTableCell>{row.title}</StyledTableCell>
                          {!isTab && (
                            <StyledTableCell>
                              {row.subjectTeacher === null ||
                              row.subjectTeacher === ""
                                ? "Not Assigned"
                                : row.subjectTeacher}
                            </StyledTableCell>
                          )}

                          <StyledTableCell>
                            <a
                              target="_blank"
                              style={{
                                padding: "0.5rem 0.8rem",
                                borderRadius: "20px",
                                fontFamily: "gilroy-regular",
                              }}
                              role="button"
                              href={
                                index === 0
                                  ? "https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf"
                                  : row.filePath
                              }
                              rel="noreferrer"
                            >
                              View
                            </a>
                            <Link
                              href={
                                index === 0
                                  ? "https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf"
                                  : row.filePath
                              }
                              style={{ textDecoration: "none" }}
                              target="_blank"
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: "100px",
                                  fontFamily: "gilroy-regular",
                                  px: 4,
                                }}
                              >
                                View
                              </Button>
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={5}
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: colors.primary,
                          }}
                        >
                          No Tutorials Available
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
                {pageCount > 1 && (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Pagination
                      pageCount={pageCount > 1 && pageCount}
                      range={1}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                )}
              </TableContainer>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default connect(null, {
  getTutorial,
})(TutorialFirst);
