import React from "react";
import { AccountSummaryWrapper } from "../../pages/student-Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets001/colors.json";
import { Navigate } from "react-router-dom";
const OngoingSession = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = Navigate();
  return (
    <AccountSummaryWrapper pt="0px">
      <table
        style={{
          borderRadius: "0px",
        }}
      >
        <tr
          className="table-head"
          style={{
            backgroundColor: "#F0E8F1",
            color: "black",
          }}
        >
          <th>TItle</th>
          <th>Date</th>
          {!isMobile && <th>Duration</th>}
          {!isMobile && <th>Status</th>}

          {!isMobile && <th>Attendance</th>}
          <th>Feedback</th>
          <th></th>
        </tr>
        <tr
          style={{
            border: "1px solid #F0E8F1",
            background: "white",
          }}
        >
          <td>How To Mix</td>
          <td>12/02/2022</td>
          {!isMobile && <td>1 hour</td>}
          {!isMobile && (
            <td>
              <p
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  background: colors.secondary,
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                Ended
              </p>
            </td>
          )}

          {!isMobile && (
            <td>
              <p
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  background: colors.secondary,
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                Attended
              </p>
            </td>
          )}

          <td>Not yet graded</td>
          <td>
            <button
              style={{
                color: colors.primary,
                padding: "8px 12px",
                borderRadius: "20px",
                border: `1px solid ${colors.primary}`,
                background: "white",
                fontWeight: "bold",
              }}
            >
              Start
            </button>
          </td>
        </tr>
        <tr
          style={{
            border: "1px solid #F0E8F1",
            background: "white",
          }}
        >
          <td>How To Mix</td>
          <td>12/02/2022</td>
          {!isMobile && <td>1 hour</td>}
          {!isMobile && (
            <td>
              <p
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  background: colors.secondary,
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                Ended
              </p>
            </td>
          )}

          {!isMobile && (
            <td>
              <p
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  background: colors.secondary,
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                Attended
              </p>
            </td>
          )}

          <td>Not yet graded</td>
          <td>
            <button
              style={{
                color: colors.primary,
                padding: "8px 12px",
                borderRadius: "20px",
                border: `1px solid ${colors.primary}`,
                background: "white",
                fontWeight: "bold",
              }}
            >
              Start
            </button>
          </td>
        </tr>
      </table>
    </AccountSummaryWrapper>
  );
};

export default OngoingSession;
