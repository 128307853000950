/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  FormFlex,
  FormFlexDiv,
  ImageUploadContainer,
  ImageWrapper,
  TabContentWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import { AuthButton } from "../../pages/auth/auth.style";
import { connect } from "react-redux";
import { getStudentProfileInfoData } from "../../actions/user";
import { InputWrapper } from "../../pages/auth/auth.style";
import GlobalStyles from "../../assets001/globalStyle";
import "react-datepicker/dist/react-datepicker.css";
import Upload from "../../assets001/icons/upload";
import Select from "../select/select";
import avatar from "../../assets001/avatar.jpeg";
import ImageUploader from "react-images-upload";
import colors from "../../assets001/colors.json";
import { useMediaQuery } from "react-responsive";
import DatePickerComponent from "../datepicker/DatePicker";
import { toast } from "react-toastify";
import Loader from "../pageLoader/loader";

const containerStyle = {
  background: "transparent",
  boxShadow: "none",
  padding: "0px",
  justifyContent: "flex-start",
  margin: "0",
};

const style = {
  background: "white",
  color: colors.primary,
};

const StudentBioData = ({ getStudentProfileInfoData }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [age, setAge] = useState("");
  const [opt, setOpt] = useState("");
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);

  const optionsArr = [
    {
      id: 1,
      name: "Value 1",
    },
    {
      id: 2,
      name: "Value 2",
    },
  ];

  const optReligion = [
    {
      id: 1,
      name: "Christianity",
    },
    { id: 2, name: "Islam" },
    { id: 2, name: "Others" },
  ];

  const optGender = [
    {
      id: 1,
      name: "MALE",
    },
    {
      id: 2,
      name: "FEMALE",
    },
  ];
  useEffect(() => {}, []);

  const getData = async () => {
    setLoading(true);
    try {
      let res = await getStudentProfileInfoData(
        token,
        phone,
        userInstance.schoolId,
        "bio-data"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        // console.log(new Date(res.data.age));
        // setDob(new Date(res.data.age));
        calculateAge();
        setLoading(false);
      } else {
        toast.error(res.data.Message);
        setLoading(false);
        console.log("something is wrong");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.data.Message);
    }
  };

  useEffect(() => {
    getData();
    // calculateAge();
  }, []);

  const calculateAge = (date = dob) => {
    const today = new Date();
    console.log(date);
    let age = today.getFullYear() - date.getFullYear();
    const month = today.getMonth() - date.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
      age--;
    }

    setAge(age);
  };
  const onDrop = (picture, url) => {
    console.log(picture);
    console.log(url);
  };
  return (
    <TabContentWrapper>
      <GlobalStyles />
      {loading ? (
        <Loader />
      ) : bioData ? (
        <>
          <FormFlex reverse={true}>
            {" "}
            <FormFlexDiv>
              {" "}
              <InputWrapper>
                <label htmlFor="Admission Number">Admission Number</label>
                <input
                  type="text"
                  placeholder="Admission Number"
                  value={bioData.admission_no}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="First Name">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  value={bioData.firstName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Last Name">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  disabled={true}
                  value={bioData.lastName}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Other Name">Other Name</label>
                <input
                  type="text"
                  placeholder="Other Name"
                  value={bioData.otherName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              {" "}
              <ImageUploadContainer>
                <ImageWrapper>
                  <img src={bioData.profileImagePath || avatar} alt="avatar" />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      height: "fit-content",
                      justifyContent: "center",
                    }}
                  >
                    <Upload />
                    <ImageUploader
                      disabled={true}
                      withIcon={false}
                      onChange={onDrop}
                      buttonText="Upload"
                      buttonClassName="disabled"
                      label=""
                      input
                      imgExtension={[".jpg", ".png"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      buttonStyles={style}
                      fileContainerStyle={{
                        ...containerStyle,
                      }}
                    />
                  </div>
                </ImageWrapper>
              </ImageUploadContainer>{" "}
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              {/* Date Picker */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "70%",
                  }}
                >
                  <InputWrapper>
                    <label htmlFor="">Date of Birth</label>
                    {/* <input type="text" /> */}

                    <DatePickerComponent
                      selected={dob}
                      setSelected={(val) => {
                        setDob(val);
                        calculateAge(val);
                      }}
                    />
                  </InputWrapper>
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  <InputWrapper>
                    <label
                      htmlFor="Age"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Age
                    </label>
                    <input
                      type="text"
                      value={age}
                      style={{
                        color: "white",
                        background: "#671e78",
                      }}
                      disabled={true}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </InputWrapper>
                </div>
              </div>

              <InputWrapper>
                <label htmlFor="Gender">Gender</label>
                <Select
                  title="Gender"
                  index="name"
                  selected={bioData.gender}
                  options={optGender}
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Place of Birth">Place Of Birth</label>
                <input
                  type="text"
                  placeholder={bioData.placeOfOrigin || ""}
                  disabled={true}
                  value="Lagos"
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="State of Origin">State Of Origin</label>
                <input
                  type="text"
                  placeholder="State of Origin"
                  value={bioData.stateOfOrigin || ""}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Residential Address">Residential Address</label>
                <input
                  type="text"
                  placeholder="Residential Address"
                  value={bioData.residentialeAddress}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Postal Address">Postal Address</label>
                <input
                  type="text"
                  placeholder="Postal Address"
                  value={bioData.residentialeAddress}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Eye Color">Eye Color</label>
                <input
                  type="text"
                  placeholder="Brown"
                  disabled={true}
                  value={bioData.eyeColor}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Home Town">Home Town</label>
                <input
                  type="text"
                  placeholder="Ojuelegba"
                  disabled={true}
                  value={bioData.hometown}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Nationality">Nationality</label>
                <input
                  type="text"
                  placeholder="Your Country"
                  disabled={true}
                  value={bioData.nationality}
                  onChange={(e) => console.log(e.target.value)}
                />
                {/* <Select
                    title="Nationality"
                    index="name"
                    selected={opt}
                    options={optionsArr}
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  /> */}
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Local Goevrnment">Local Government</label>
                <input
                  type="text"
                  placeholder="Lagos Island Local Government"
                  disabled={true}
                  value={bioData.localGovernment}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Home Town">
                  Nearest Bus-stop to your house
                </label>
                <input
                  type="text"
                  placeholder="Enter Busstop"
                  value={bioData.nearestBustop}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Religion">Religion</label>
                <input
                  type="text"
                  placeholder="Enter Religion"
                  value={bioData.religion}
                  onChange={(e) => console.log(e.target.value)}
                />
                {/* <Select
                  title="Religion"
                  index="name"
                  selected={bioData.religion}
                  options={optReligion}
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                /> */}
              </InputWrapper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  <InputWrapper>
                    <label htmlFor="weight">Weight</label>
                    <input
                      style={{
                        marginTop: "5px",
                      }}
                      type="text"
                      placeholder="20kg"
                      value={bioData.weight}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </InputWrapper>
                </div>
                <div style={{ width: "45%" }}>
                  <InputWrapper>
                    <label htmlFor="height">Height</label>
                    <input
                      type="text"
                      placeholder="100m"
                      value={`${bioData.height}ft`}
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </InputWrapper>
                </div>
              </div>
              <InputWrapper>
                <label htmlFor="Denomination">Denomination</label>
                <input
                  type="text"
                  placeholder="Winners"
                  value={bioData.denomination}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <FormFlex>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="Postal Address">
                  Name and Address of Denomination
                </label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="20 Edmund Street"
                  value={bioData.denominationAddress}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="Home Town">
                  Role of child/ward in place of worship
                </label>
                <input
                  type="text"
                  disabled={true}
                  placeholder="Boy's Scout"
                  value={bioData.childOrWardWorshipRole}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
            <FormFlexDiv>
              <InputWrapper>
                <label htmlFor="phone number">Name of the Vicar/Pastor</label>
                <input
                  type="text"
                  placeholder="Ojuelegba"
                  value={bioData.vicorOrPastorName}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="phone number">
                  Phone Number of the Vicar/Pastor
                </label>
                <input
                  type="text"
                  placeholder="Ojuelegba"
                  value={bioData.vicorOrPastorPhoneNo}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                />
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>

          <div
            style={{
              display: "none",
              justifyContent: "end",
            }}
          >
            <AuthButton width={isMobile ? "100%" : "30%"} onSubmit={null}>
              <div>
                <span>Update Details</span>
              </div>
              {/* Send Code */}
            </AuthButton>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            color: colors.primary,
            fontWeight: "bold",
          }}
        >
          No Records For Student
        </div>
      )}
    </TabContentWrapper>
  );
};

export default connect(null, { getStudentProfileInfoData })(StudentBioData);
