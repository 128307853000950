/* eslint-disable no-unused-vars */
import {
  FETCHING_USER,
  FETCHING_USER_DONE,
  FAILED_FETCHING_USER,
} from "../types/index";

const authStatus = Boolean(localStorage.getItem("lasmabAuthStatus"));
const lasmabAuthToken = localStorage.getItem("lasmabAuthToken");
const lasmabBrowserToken = localStorage.getItem("lasmabBrowserToken");
const userInstance = JSON.parse(localStorage.getItem("lasmabObj"));
const initialState = {
  loading: false,
  authStatus: authStatus,
  token: lasmabAuthToken ? lasmabAuthToken : "",
  browserToken: lasmabBrowserToken ? lasmabBrowserToken : "",
  user: userInstance ? userInstance : null,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_USER:
      return {
        ...state,
        loading: action.loading,
      };
    case FETCHING_USER_DONE:
      return {
        ...state,
        loading: action.loading,
        user: action.user,
        token: action.token,
        authStatus: action.authStatus,
        error: action.error,
      };
    case FAILED_FETCHING_USER:
      return {
        ...state,
        loading: action.loading,
        authStatus: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default userReducer;
