/* eslint-disable no-unused-vars */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  createRef,
  forwardRef,
} from "react";
// import styled from "styled-components";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import { connect } from "react-redux";
import ResultCard from "../../components/Result/ResultCard";
import "../../App.css";
import { useMediaQuery } from "react-responsive";

import { fetchStampSign, getResults, viewResult } from "../../actions/user";
import { toast } from "react-toastify";
import Loader from "../../components/pageLoader/loader";
import WelcomeText from "../../components/WelcomeText";
import api from "../../api/user";
import {
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import Select from "../../components/select/select";
import { InputWrapper } from "../../pages/auth/auth.style";
import axios from "axios";

// import SearchIcon from '@mui/icons-material/Search';

let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -38%)",
  width: {
    lg: 1100,
    xs: "98%",
    md: 880,
    sm: "90%",
  },
  height: 700,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: {
    lg: 4,
    md: 4,
    sm: 3,
    xs: 2,
  },
  overflow: "scroll",
  scrollbars: "hidden",
  // scrollbars: "hidden",
};
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FFF",

  border: "1px solid #691e78",
  borderRadius: "100px",
  "&:hover": {
    // backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "345px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Result = ({ getResults, viewResult }) => {
  const baseUrl = process.env.REACT_APP_API_ENDPOINT_TWO;
  const isTab = useMediaQuery({ maxWidth: 768 });
  const token = localStorage.lasmabAuthToken || "";
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const [result, setResult] = useState(null);
  const viewData = useCallback(async (data) => {
    // toast.warning("Please wait...", {
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose: 1000,
    // });
    console.log("inside");
    console.log(data);
    setLoading(true);
    try {
      let res = await viewResult(token, data);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setLoading(false);
        setResult(res.data);
        handleOpen();
        return;
      }
      if (!res.status && res.statusCode === 6) {
        setLoading(false);
        toast.error(res.message);
        return;
      }
      if (res.status === 500) {
        setLoading(false);
        toast.error(res.data.message);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.data.message);
      console.log(error);
    }
  }, []);
  const getData = useCallback(
    async (id) => {
      setLoading(true);
      try {
        let res = await getResults(token, id);
        if (res.status && res.statusCode === 2) {
          setData(res.data);
        }
      } catch (err) {
        toast.error(err.data.message);
        console.log("something is wrong");
      } finally {
        setLoading(false);
      }
    },
    [token, getResults]
  );

  const componentRef = useRef();
  const onBeforeGetContentResolve = React.useRef(null);
  const [loader, setLoader] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [stampSign, setStampSign] = useState(null);
  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const getStampSign = useCallback(async () => {
    setLoading(true);
    try {
      let res = await api.getSchoolStampAndSignature(
        token,
        userInstance.schoolId
      );

      if (res.status && res.statusCode === 2) {
        setStampSign(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [token, userInstance.schoolId]);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoader(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoader(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoader, setText]);

  const reactToPrintContent = React.useCallback(() => {
    console.log("inside");
    console.log(componentRef);
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  useEffect(() => {
    getStampSign();
  }, [getStampSign]);

  const getSessions = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/v1/Results/UpdateScore/Sessions`
      );
      if (res.status === 200) {
        setSessions(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSessions();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          <Box sx={{ borderBottom: "2px solid #671E78" }}>
            <WelcomeText />
            {/* <Typography
              variant="p"
              sx={{ lineHeight: "23px", fontSize: "20px" }}
            >
              Hi there! Welcome
            </Typography> */}
            <Typography
              variant="h1"
              sx={{
                mt: 3,
                fontSize: {
                  lg: "42px",
                  md: "42px",
                  sm: "38px",
                  xs: "30px",
                },
              }}
            >
              Result
            </Typography>
          </Box>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions}
                  setSelected={(val) => {
                    setSession({ ...session, id: val.id, name: val.session });
                    if (val.id) {
                      getData(val.id);
                    }
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          {/* .......,,,,,,,,,,,,,,.............Tables..............,,,,,,,,,,,,,,,,............... */}
          <Box
            mt={5}
            sx={{
              border: "1px solid #671E78",
              borderRadius: "20px",
              position: "relative",
              zIndex: 0,
            }}
          >
            <Box
              sx={{
                display: "none",
                borderRadius: "20px 20px 0px 0px",
                pt: 4,
                background: "rgba(103, 30, 120, 0.1)",
                height: "100px",
                pl: { xs: 2.5 },
              }}
            >
              <Search
                sx={{
                  width: {
                    lg: "345px",
                    md: "330px",
                    sm: "auto",
                    xs: "auto",
                    pl: { xs: 6 },
                  },
                }}
              >
                <SearchIconWrapper>
                  <BsSearch />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead
                  sx={{
                    backgroundColor: "rgba(103, 30, 120, 0.1)",
                    width: "100%",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>Session</StyledTableCell>
                    <StyledTableCell>Term</StyledTableCell>
                    <StyledTableCell>Class</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                  {data.length > 0 ? (
                    data.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell>{row.session}</StyledTableCell>
                        <StyledTableCell>{row.term}</StyledTableCell>
                        <StyledTableCell>{row.className}</StyledTableCell>
                        <StyledTableCell>
                          {row.status === "1" ? (
                            <Box
                              sx={{
                                background: "rgba(103, 30, 120, 0.1) ",
                                py: 1,
                                textAlign: "center",
                                color: "#671E78",
                                borderRadius: "4px",
                              }}
                            >
                              Available
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                background: "rgba(170, 16, 16, 0.1) ",
                                py: 1,
                                textAlign: "center",
                                color: "#AA1010",
                                borderRadius: "4px",
                              }}
                            >
                              Unavailable
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              let data = {
                                sessionId: row.sessionId,
                                currentTermId: row.termId,
                                classId: row.classId,
                              };
                              viewData(data);
                            }}
                            sx={{
                              borderRadius: "100px",
                              fontFamily: "gilroy-regular",
                              px: 4,
                            }}
                            disabled={row.status === "0"}
                          >
                            View
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={6}
                        style={{ textAlign: "center" }}
                      >
                        No Results Available For This Student
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {/* {} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {/* ............,,,,,,,,///////////////////................MODAL.............//////////////// */}
        <Modal
          sx={{
            minWidth: isTab ? "98%" : "80&",
          }}
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="result">
            <ResultCard
              resultData={result}
              stampSign={stampSign}
              print={handlePrint}
              close={() => {
                handleClose();
                setResult(null);
              }}
            />
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default connect(null, {
  getResults,
  viewResult,
})(Result);
