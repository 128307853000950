import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TabContainer,
  TabLinkContainer,
  Input,
  Search,
  Icon,
  AccountSummaryWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import { BsSearch } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { ButtonFlex } from "../../pages/auth/auth.style";
import colors from "../../assets001/colors.json";
import { Link } from "react-router-dom";
import Pagination from "../pagination_one/pagination";
import moment from "moment";
import { paginateData } from "../../services/utils";
const CList = ({ allData, getData }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [arrData, setArrData] = useState([]);
  const [itemOffSet, setitemOffSet] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sQuery, setSQuery] = useState("");
  const filterSearch = async (e) => {
    console.log(e.target.value);
    if (e.target.value === "") {
      getData();
    } else {
      let arr = [];
      const queryRegex = new RegExp(e.target.value, "i");
      allData.forEach((item) => {
        Object.values(item).forEach((val) =>
          queryRegex.test(val) ? arr.push(item) : ""
        );
      });

      setArrData(arr);
      setPageCount(0);
    }
  };
  useEffect(() => {
    paginateData(
      allData,
      setArrData,
      currentPage,
      itemOffSet,
      setitemOffSet,
      setPageCount
    );
  }, [allData, currentPage]);

  console.log("arrDara", arrData);
  return (
    <TabContainer
      style={{ marginTop: "0", paddingBottom: "0px", border: "none" }}
    >
      <TabLinkContainer bg="#F0E8F1" brtl="0px" brtr="0px">
        <Input width="85%">
          <Icon>
            <BsSearch style={{ color: colors.primary, fontWeight: "bold" }} />
          </Icon>
          <Search
            value={sQuery}
            type="search"
            placeholder="Date(e.g 2021-05-08), Subject, Title & Subject Teacher"
            onChange={(e) => {
              // console.log(e.target.value);
              setSQuery(e.target.value);
              filterSearch(e);
            }}
          />
        </Input>
      </TabLinkContainer>
      <AccountSummaryWrapper pt="0px">
        {/* {loading ? (
            <Loader />
          ) : ( */}
        <table style={{ borderRadius: "0px" }}>
          <tr
            className="table-head"
            style={{
              backgroundColor: "#F0E8F1",
              color: "black",
            }}
          >
            <th>Date</th>
            <th>Subject</th>
            {!isMobile && (
              <th
                style={{
                  width: "35%",
                }}
              >
                Classwork Title/Description
              </th>
            )}
            <td>Status</td>
            <th>My Score</th>
            {!isMobile && <th>Subject Teacher</th>}

            <th></th>
          </tr>
          {arrData?.length > 0 ? (
            arrData.map((item, index) => (
              <tr
                style={{ background: "white", border: "1px solid #F0E8F1" }}
                key={index}
              >
                <td
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {moment(item.post_date).format("YYYY-MM-DD")}
                </td>

                <td>{item.subject !== null ? item.subject : "Not Assigned"}</td>
                {!isMobile && (
                  <td
                    style={{
                      width: "35%",
                    }}
                  >
                    {item.description !== "" ? item.description : item.title}
                  </td>
                )}
                <td>
                  {item.status === 2 ? (
                    <span className="bg-[#ff00001a] text-[#ff0000] px-3 py-1 rounded-lg">
                      Closed
                    </span>
                  ) : (
                    <span className="bg-[#13f06673] text-[#035703fc] px-3 py-1 rounded-lg ">
                      Open
                    </span>
                  )}
                </td>
                <td>
                  {item.score === null || item.score === "" ? (
                    <p
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        background: "#ff00001a",
                        fontWeight: "bold",
                        color: "red",
                        width: "fit-content",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      Not Marked Yet
                    </p>
                  ) : (
                    item.score
                  )}
                </td>
                {!isMobile && (
                  <td>
                    {" "}
                    {item.subjectTeacher !== null
                      ? item.subjectTeacher
                      : "Not Assigned"}
                  </td>
                )}

                <td>
                  <ButtonFlex
                    style={{
                      justifyContent: "center",
                      paddingRight: "10px",
                    }}
                  >
                    <Link
                      to={`/classwork/${item.classWorkId}`}
                      style={{
                        color: colors.primary,
                        borderRadius: "20px",
                        padding: "5px 12px",
                        border: `2px solid ${colors.primary}`,
                        background: "white",
                      }}
                    >
                      View
                    </Link>
                  </ButtonFlex>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={!isMobile ? 6 : 4}
                style={{
                  color: colors.primary,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                No Classwork For This Student
              </td>
            </tr>
          )}
        </table>

        {pageCount > 1 && (
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Pagination
              pageCount={pageCount > 1 && pageCount}
              range={1}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </AccountSummaryWrapper>
    </TabContainer>
  );
};

CList.propTypes = {
  allData: PropTypes.array,
};

CList.defaultProps = {
  allData: [],
};
export default CList;
