import { Navigate, Outlet } from "react-router-dom";

const loggedInStatus = () => {
  const authStatus = localStorage.lasmabAuthStatus || "";
  const token = localStorage.lasmabAuthToken || "";
  const phone = localStorage.lasmabPhone || "";
  if (authStatus && authStatus === "true" && token !== "" && phone !== "") {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = () => {
  const isLoggedIn = loggedInStatus();
  return !isLoggedIn ? <Navigate to="/" /> : <Outlet />;
};

export default ProtectedRoutes;
