/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { AccountSummaryWrapper } from "../../pages/student-Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets001/colors.json";
import Loader from "../pageLoader/loader";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getStudentMedicalInfo } from "../../actions/user";
import moment from "moment";
const Complaints = ({ getStudentMedicalInfo }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [bioData, setBioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentMedicalInfo(
        token,
        userInstance.studentId,
        userInstance.schoolId,
        "complaints"
      );
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }

      console.log(res);
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
    }
  }, [
    getStudentMedicalInfo,
    token,
    userInstance.schoolId,
    userInstance.studentId,
  ]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
            }}
          >
            <tr
              className="table-head"
              style={{
                backgroundColor: "#F0E8F1",
                color: "black",
              }}
            >
              <th>Name</th>
              <th>Date</th>
              {!isMobile && <th>Time</th>}

              <th>Temperature</th>
              <th>Complaint</th>
              <th>Prescription</th>

              {!isMobile && <th>Remark</th>}
              {/* {!isMobile && <th>Remark</th>} */}
            </tr>
            {bioData.length > 0 ? (
              bioData.map((item, index) => (
                <tr style={{ border: "1px solid #F0E8F1" }} key={index}>
                  <td>{item.name}</td>
                  <td>{moment(item.date).format("YYYY/MM/DD")}</td>
                  {/* <td>Dr Pan Peter</td> */}
                  {!isMobile && <td>{moment(item.data).format("LT")}</td>}
                  <td>{item.temperature}</td>
                  <td>{item.complaint}</td>
                  <td>{item.prescription}</td>
                  {!isMobile && <td>{item.remark}</td>}
                  {/* {!isMobile && <td></td>} */}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={!isMobile ? 7 : 4}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  No Drug Usage For This Student
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      )}
    </>
  );
};

export default connect(null, { getStudentMedicalInfo })(Complaints);
