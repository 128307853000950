import { useRoutes } from "react-router-dom";
// import Home from "../pages/Home";
import Verify from "../pages/auth/Verify";
import Login from "../pages/auth/Login";
// import SendCode from "../pages/auth/SendCode";
import {
  Assignment,
  Cbt,
  Chats,
  Classwork,
  Ledger,
  LiveChats,
  Medical,
  Profile,
  Result,
  Tutorial,
  Dashboard,
  SharedLayout,
} from "../pages/student-Dashboard";

import ClassworkParent from "../components/classAssignment/ClassworkParent";
import ClassworkChild from "../components/classAssignment/ClassworkChild";
import AssignmentParent from "../components/classAssignment/AssignmentParent";
import AssignmentChild from "../components/classAssignment/AssignmentChild";
import ResetPassword from "../pages/auth/ResetPassword";

import TutorialFirst from "../components/Tutorial-Screen/TutorialFirst";
import TutorialSecond from "../components/Tutorial-Screen/TutorialSecond";
import Quiz from "../components/Quiz/Quiz";

import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";

export default function Routes() {
  let element = useRoutes([
    {
      element: <ProtectedRoutes />,
      children: [
        {
          element: <SharedLayout />,
          children: [
            {
              element: <Classwork />,
              children: [
                { path: "classwork", element: <ClassworkParent /> },
                { path: "classwork/:slug", element: <ClassworkChild /> },
              ],
            },
            {
              path: "/dashboard",
              element: <Dashboard />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
            {
              path: "result",
              element: <Result />,
            },
            {
              element: <Assignment />,
              children: [
                { path: "assignment", element: <AssignmentParent /> },
                { path: "assignment/:slug", element: <AssignmentChild /> },
              ],
            },

            {
              element: <Tutorial />,
              children: [
                { path: "tutorial", element: <TutorialFirst /> },
                { path: "tutorial/:slug", element: <TutorialSecond /> },
              ],
            },
            {
              path: "cbt",
              element: <Cbt />,
            },
            {
              path: "chat",
              element: <Chats />,
            },
            {
              path: "live-class",
              element: <LiveChats />,
            },
            {
              path: "medical",
              element: <Medical />,
            },
            {
              path: "ledger",
              element: <Ledger />,
            },
          ],
        },
      ],
    },

    {
      element: <PublicRoutes />,

      children: [
        {
          index: "/",
          element: <Login />,
        },
        // {
        //   path: "/send-code",
        //   element: <SendCode />,
        // },
        {
          path: "/verify",
          element: <Verify />,
        },
        {
          path: "/quiz",
          element: <Quiz />,
        },
        {
          path: "/resetpassword",
          element: <ResetPassword />,
        },
      ],
    },
  ]);

  return element;
}
