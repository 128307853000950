import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Grid, Typography, Divider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card } from "../../components/dashboard";
import { BiChalkboard } from "react-icons/bi";
import {
  MdAssignment,
  MdCalendarToday,
  MdOutlineEventAvailable,
  MdOutlineComputer,
} from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { ImNewspaper } from "react-icons/im";
import {
  FaChalkboardTeacher,
  FaRegHospital,
  FaRegListAlt,
} from "react-icons/fa";
import { getStudentProfileInfoData } from "../../actions/user";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import Loader from "../../components/pageLoader/loader";
// import HiWelcome from "../../components/HiWelcome";
import WelcomeText from "../../components/WelcomeText";
let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});

const Dashboard = ({ user, pLoading, getStudentProfileInfoData }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getData = async () => {
    setLoading(true);
    try {
      let res = await getStudentProfileInfoData(
        token,
        phone,
        userInstance.schoolId,
        "dashboard-counts"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.data?.Message);
    }
  };
  useEffect(() => {
    getData();
    // calculateAge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          <Box sx={{ borderBottom: "2px solid #671E78" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: isMobile ? "column-reverse" : "row",
              }}
            >
              <WelcomeText />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile ? "14px" : "18px",
                }}
              >
                {user.schoolName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: isMobile ? "column-reverse" : "row",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  mt: 3,
                  fontSize: {
                    lg: "35px",
                    md: "32px",
                    sm: "30px",
                    xs: "28px",
                  },
                }}
              >
                Student Dashboard{" "}
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  mt: 3,
                  fontSize: "18px",
                }}
              >
                {user?.class}
              </Typography>
            </Box>
          </Box>

          <Box mt={5} pb={3}>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              bioData !== null && (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    width: { lg: "100%", md: "90%", sm: "100%", xs: "100%" },
                    justifyContent: {
                      lg: "normal",
                      md: "normal",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<MdAssignment />
                      caption="Classwork"
                      value={bioData.classWorkCount}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    {" "}
                    <Card
                      icon=<FaChalkboardTeacher />
                      caption=" Total Live class"
                      value={bioData.liveClassCount}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    {" "}
                    <Card
                      icon=<BiChalkboard />
                      caption="Class History"
                      value={bioData.classHistoryCount}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<FaRegHospital />
                      caption="Total Cinic Visit"
                      value={bioData.clinicVisitCount}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<TbReportAnalytics />
                      caption="Total Result "
                      value={bioData.resultCount}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<MdAssignment />
                      caption="Homework/Assignment"
                      value={bioData.assignmentCount}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<ImNewspaper />
                      caption="Newsletter"
                      value={bioData.newsletter}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<MdOutlineEventAvailable />
                      caption="Total Class Attendance"
                      value={bioData.classAttendanceCount}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<MdCalendarToday />
                      caption="School Calender"
                      value=" "
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card icon=<FaRegListAlt /> caption="Timetable" value=" " />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      icon=<MdOutlineComputer />
                      caption="CBT "
                      value={bioData.cbtCount}
                    />
                  </Grid>
                </Grid>
              )
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.userState.user,
  pLoading: state.userState.loading,
});

export default connect(mapStateToProps, { getStudentProfileInfoData })(
  Dashboard
);
