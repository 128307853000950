/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
const endpoint = process.env.REACT_APP_API_ENDPOINT;
const api_version = process.env.REACT_APP_API_VERSION;
const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;

export default {
  postForgetPin: (phoneNumber) =>
    axios
      .post(`${endpoint}/api/v${api_version}/Auth/Forget-PIN`, {
        phoneNumber,
        accountType: 1,
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  postResetPin: (data) =>
    axios
      .post(`${endpoint}/api/v${api_version}/Auth/Reset-PIN`, data)
      .then((res) => res.data)
      .catch((err) => err.response),
  postChangePin: (token, data, browserToken) =>
    axios
      .post(`${endpoint}/api/v${api_version}/Auth/Change-PIN`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          browserToken: `${browserToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  postSendCode: (phoneNumber, email, countryCode, accountType) => {
    let data = {
      phoneNumber,
      email,
      countryCode,
      accountType,
    };

    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Send-Code`, data)
      .then((res) => res.data)
      .catch((err) => err.response);
  },

  postVerifyCode: (phoneNumber, code, accountType) => {
    let data = {
      phoneNumber,
      code,
      accountType,
    };
    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Verify-code`, data)
      .then((res) => res.data)
      .catch((err) => err.response);
  },

  postCreatePin: (phoneNumber, pin, browserToken, accountType) => {
    let data = {
      phoneNumber,
      pin,
      browserToken,
      accountType,
    };

    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Create-Pin`, data, {
        headers: {
          Authorization: `Bearer ${browserToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  authStudentLogin: (phoneNumber, pin, browserToken, accountType) => {
    let data = {
      phoneNumber,
      pin,
      browserToken,
      accountType,
    };
    console.log(data);
    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Student-Login`, data, {
        headers: {
          Authorization: `Bearer ${browserToken}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },

  authParentLogin: (phoneNumber, pin, broswerToken, accountType) => {
    let data = {
      phoneNumber,
      pin,
      broswerToken,
      accountType,
    };
    console.log(data);
    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Parent-Login`, data, {
        headers: {
          Authorization: `Bearer ${broswerToken}`,
        },
      })
      .then((res) => res.data);
  },

  createParentProfile: (
    phoneNumber,
    fullName,
    title,
    email,
    officeAddress,
    placeOFWork,
    dob,
    nameOfChildren,
    educationQualificatin,
    postalAddress,
    nearestBusStop,
    permanentAddress,
    profession,
    statusRankDesignation,
    profileImagePath,
    religion,
    placeOfworship,
    denomination,
    statusInPlaceOfWorship
  ) => {
    let data = {
      phoneNumber,
      fullName,
      title,
      email,
      officeAddress,
      placeOFWork,
      dob,
      nameOfChildren,
      educationQualificatin,
      postalAddress,
      nearestBusStop,
      permanentAddress,
      profession,
      statusRankDesignation,
      profileImagePath,
      religion,
      placeOfworship,
      denomination,
      statusInPlaceOfWorship,
    };

    return axios
      .post(`${endpoint}/api/v${api_version}/Auth/Parent-Login`, data, {
        // headers: {
        //   Authorization: `Bearer ${broswerToken}`,
        // },
      })
      .then((res) => res.data);
  },

  // getStudentProfile
  getStudentProfile: (token) =>
    axios
      .get(`${endpoint}/api/v${api_version}/Auth/GetStudent-Profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),

  getStudentProfileInfoData: (token, phoneNumber, schoolId, infoType) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/StudentProfile/${infoType}?phoneNumber=${phoneNumber}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // get Student Medical Info
  getStudentMedicalInfo: (token, studentId, schoolId, infoType) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/StudentMedical/${infoType}?studentId=${studentId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  // classwork
  getClassworkList: (token, studentId, schoolId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Classwork/classwork-list?studentId=${studentId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getSingleClasswork: (token, id, schoolId, studentId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Classwork/classworkDetails/${id}?studentId=${studentId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getClassworkQuestions: (token, classworkId, studentId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Classwork/ClassWork-Questions?ClassWorkId=${classworkId}&studentId=${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  submitClassworkAnswer: (token, answer, id, studentId, questionId, type) => {
    let data = {
      answer,
      classWorkId: Number(id),
      studentId: studentId.toString(),
      questionId: questionId.toString(),
    };
    console.log(token, data);

    return axios
      .post(`${endpoint}/api/v${api_version}/Classwork/${type}-answer`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  submitClassworkComment: (token, comment, id, senderId, senderUserType) => {
    let data = {
      comment,
      classWorkId: Number(id),
      senderId: senderId.toString(),
      senderUserType,
    };
    console.log(data);

    return axios
      .post(`${endpoint}/api/v${api_version}/Classwork/submit-comment`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  getClassworkComments: (token, classworkId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Classwork/Classwork-Comments?ClassWorkId=${classworkId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),

  getAssignmentList: (token, studentId, schoolId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Assignment/assignment-list?studentId=${studentId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSingleAssignment: (token, id, schoolId, studentId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Assignment/AssignmentDetails/${id}?studentId=${studentId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getAssignmentQuestions: (token, id, studentId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Assignment/Assignment-Questions?AssignmentId=${id}&studentid=${studentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  submitAssignmentAnswer: (token, answer, id, studentId, questionId, type) => {
    let data = {
      answer,
      assignmentId: Number(id),
      studentId: studentId.toString(),
      questionId: questionId.toString(),
    };
    return axios
      .post(`${endpoint}/api/v${api_version}/Assignment/${type}-answer`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  submitAssignmentComment: (token, comment, id, senderId, senderUserType) => {
    let data = {
      comment,
      assignmentId: Number(id),
      senderId: senderId.toString(),
      senderUserType,
    };
    return axios
      .post(`${endpoint}/api/v${api_version}/Assignment/submit-comment`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  uploadFileAnswer: (
    token,
    studentId,
    schoolId,
    classId,
    fileName,
    fileContentPath,
    attachmentTypeId,
    AnsType
  ) => {
    let data = {
      fileName,
      fileContentPath,
      attachmentTypeId,
      studentId,
      schoolId,
      classId,
      AnsType,
    };
    console.log(data);
    return axios
      .post(
        `${endpoint}/api/v${api_version}/Classwork/upload-Answer-File`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  getAssignmentComments: (token, assignmentId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Assignment/assignment-Comments?AssignmentId=${assignmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getTutorial: (token, classId, schoolId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Tutorial/resources?classId=${classId}&schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getTutorialComments: (token, schoolId, tutorialId) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/Tutorial/GetComments?SchoolId=${schoolId}&Tutorial=${tutorialId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  submitTutorialComment: (token, comment, senderId, senderUserType, id) => {
    let data = {
      comment,
      tutorialId: Number(id),
      senderId: senderId.toString(),
      senderUserType,
    };
    return axios
      .post(`${endpoint}/api/v${api_version}/Tutorial/submit-comment`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  uploadAttachment: (token, data) => {
    return axios
      .post(
        `${endpoint}/api/v${api_version}/StudentProfile/Attachment-Upload`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  deleteAttachment: (token, id) => {
    return axios
      .delete(
        `${endpoint}/api/v${api_version}/StudentProfile/DeleteAttachment?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response);
  },
  getStudentLedger: (token, schoolId, studentId, dateFrom, dateTo) =>
    axios
      .get(
        `${endpoint}/api/v${api_version}/StudentProfile/Get-Student-Ledgers?SchoolId=${schoolId}&StudentId=${studentId}&DateFrom=${dateFrom}&DateTo=${dateTo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSchoolProfile: (token, id) =>
    axios
      .get(`${endpoint}/api/v${api_version}/Website/GetSchoolProfile/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),

  getResults: (token, sessionId) =>
    axios
      .post(
        `${endpoint}/api/v${api_version}/StudentResult/Result-View?sessionId=${sessionId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  viewResult: (token, data) =>
    axios
      .post(`${endpoint}/api/v${api_version}/StudentResult/Term`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response),
  getUserGuides: (token, accountType) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetUserGuide?accounttype=${accountType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getUserGuideLink: (token, accountType, qid) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/ControlPanelStaffAccountSetUp/GetUserguideLink?accounttype=${accountType}&qid=${qid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
  getSchoolStampAndSignature: (token, schoolId) =>
    axios
      .get(
        `${endpoint_two}/api/v${api_version}/MySchool/get-SchoolStampAndSignature?schoolId=${schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => err.response),
};
