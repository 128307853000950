/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useMediaQuery } from "react-responsive";

import {
  TabContainer,
  TabLinkContainer,
  TabLink,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import { connect } from "react-redux";

import { getAssignmentList } from "../../actions/user";
import { toast } from "react-toastify";
import Pagination from "../pagination_one/pagination";
import moment from "moment";
import Loader from "../pageLoader/loader";
import { paginateData } from "../../services/utils";
import AList from "./AList";

const AssignmentParent = ({ getAssignmentList }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";
  const [loading, setLoading] = useState(true);
  const [obj, setObj] = useState(null);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getAssignmentList(
        token,
        userInstance.studentId,
        userInstance.schoolId
      );
      if (res.status === true && res.statusCode === 2) {
        setObj(res.data);
        setLoading(false);
      } else {
        toast.error(res.data.message);
        setLoading(false);
        console.log("something is wrong");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err.data.message);
    }
  }, [token, userInstance]);
  useEffect(() => {
    getData();
  }, []);

  const [tab, setTab] = useState(1);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth={"50%"}
          >
            Pending Assignments
          </TabLink>
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth={"50%"}
          >
            Submitted Assignments
          </TabLink>
        </TabLinkContainer>
        {tab === 1 && (
          <AList getData={getData} allData={obj?.pendingAssignments} />
        )}

        {tab === 2 && (
          <AList getData={getData} allData={obj?.attemptedAssignments} />
        )}
      </TabContainer>
    </>
  );
};

export default connect(null, {
  getAssignmentList,
})(AssignmentParent);
