import { Box } from "@mui/system";
import React, { useState } from "react";
import {
  TabContainer,
  TabLinkContainer,
  TabLink,
  TabContent,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import Comment from "./Comment";
import Resources from "./Resources";
const TutorialSecond = () => {
  const [tab, setTab] = useState(1);
  return (
    <Box sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}>
      <TabContainer>
        <TabLinkContainer style={{ justifyContent: "space-around" }}>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            style={{ border: "none" }}
          >
            Resources
          </TabLink>
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            style={{ border: "0px" }}
          >
            Comment
          </TabLink>
        </TabLinkContainer>
        {tab === 1 && (
          <TabContent>
            <Resources name="Assignment" />
          </TabContent>
        )}
        {tab === 2 && (
          <TabContent>
            <Comment name="Assignment" />
          </TabContent>
        )}
      </TabContainer>
    </Box>
  );
};

export default TutorialSecond;
