import React, { useState, useEffect, useCallback } from "react";
import {
  FormFlex,
  FormFlexDiv,
  PageWrapper,
  AccordionText,
  InlineLabelGroup,
  InlineLabelWrapper,
  SignatureBox,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import colors from "../../assets001/colors.json";
import { InputWrapper } from "../../pages/auth/auth.style";
import Loader from "../pageLoader/loader";
import Select from "../select/select";
import { getStudentMedicalInfo } from "../../actions/user";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const MedicalHistory = ({ getStudentMedicalInfo }) => {
  // const [dob, setDob] = useState(new Date(2014, 0, 3));
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  // const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [bioData, setBioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [opt, setOpt] = useState("");
  const optionsArr = [
    {
      id: 1,
      name: "Yes",
      value: "true",
    },
    {
      id: 2,
      name: "No",
      value: "false",
    },
  ];

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentMedicalInfo(
        token,
        userInstance.studentId,
        userInstance.schoolId,
        "medical-history"
      );
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
    }
  }, [
    getStudentMedicalInfo,
    token,
    userInstance.schoolId,
    userInstance.studentId,
  ]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : bioData ? (
        <PageWrapper>
          <AccordionText>
            Does the child suffer from any of the following?
          </AccordionText>
          {/* 1 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Sickle Cell Disease</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={bioData.isSickleCell === true ? "true" : "false"}
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>{" "}
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Disease Of The Eye</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={bioData.hasEyeDisease === true ? "true" : "false"}
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>
          {/* 2 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Epilepsy</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={bioData.hasEpilespy === true ? "true" : "false"}
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>{" "}
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Disease Of the Ear</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={bioData.hasEarDisease === true ? "true" : "false"}
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>
          {/* 3 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Diabetics Mellitus</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={bioData.hasDiabetics === true ? "true" : "false"}
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>{" "}
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Disease of the Nose and throat</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasNoseDisease === true ? "true" : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>
          {/* 4 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Tuberculosis</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasTuberculosis === true ? "true" : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>{" "}
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Respiratory Disease</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasRespiratoryDisease === true ? "true" : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>{" "}
          {/* 5 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Heart Disease</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasHeartDisease === true ? "true" : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>{" "}
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">
                      Alergies e.g Asthmaor Skin Dissease
                    </label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={bioData.hasAlergies === true ? "true" : "false"}
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>{" "}
          {/* 6 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Kidney Disease</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasKidneyDisease === true ? "true" : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                    disabled={true}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>{" "}
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Mental or Nervous Disease</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasMentalOrNervousDisease === true
                        ? "true"
                        : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>{" "}
          {/* 7 */}
          <FormFlex>
            <FormFlexDiv width="45%">
              <InlineLabelGroup>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper>
                    <label htmlFor="">Disease of the Digestive System</label>
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <Select
                    disabled={true}
                    title="Status"
                    index="name"
                    selected={
                      bioData.hasDigestiveDisease === true ? "true" : "false"
                    }
                    options={optionsArr}
                    thirdOption="value"
                    setSelected={(val) => {
                      console.log(val);
                      setOpt(val.name);
                    }}
                  />
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </FormFlexDiv>
          </FormFlex>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">
                  Does your child has any type of Handicap?
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <Select
                  disabled={true}
                  title="Status"
                  index="name"
                  selected={bioData.isChild_HandCap === true ? "true" : "false"}
                  options={optionsArr}
                  thirdOption="value"
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">
                  Has your child been admitted into a hospital before?
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <Select
                  disabled={true}
                  title="Status"
                  index="name"
                  selected={
                    bioData.isChildAdmitted_To_Hospital === true
                      ? "true"
                      : "false"
                  }
                  options={optionsArr}
                  thirdOption="value"
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">
                  Has your child been operated on before?
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <Select
                  disabled={true}
                  title="Status"
                  index="name"
                  selected={
                    bioData.isChildOperatedBefore === true ? "true" : "false"
                  }
                  options={optionsArr}
                  thirdOption="value"
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          {/* If Yes */}
          {/*  */}
          <FormFlex>
            <FormFlexDiv width="100%">
              <InputWrapper>
                <label htmlFor="">If Yes give details</label>
                <textarea
                  cols="30"
                  rows="3"
                  value={bioData.operationDetails}
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormFlexDiv>
          </FormFlex>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InputWrapper>
                <label htmlFor="">
                  Is your child on any special medication?
                </label>
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper>
                <Select
                  disabled={true}
                  title="Status"
                  index="name"
                  selected={
                    bioData.isChildOnMedication === true ? "true" : "false"
                  }
                  options={optionsArr}
                  thirdOption="value"
                  setSelected={(val) => {
                    console.log(val);
                    setOpt(val.name);
                  }}
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>
          <SignatureBox>
            <p>{bioData.parentSignatureDate}</p>
            <div></div>
            <p>Parent Signature with date</p>
          </SignatureBox>
        </PageWrapper>
      ) : (
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: colors.primary,
          }}
        >
          No Medical History For Student Found
        </div>
      )}
    </>
  );
};

export default connect(null, { getStudentMedicalInfo })(MedicalHistory);
