import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";

// const store = configureStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk, logger))
// );

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, logger],
});

export default store;
