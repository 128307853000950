import React from "react";
import styled from "styled-components";

const Cards = styled.div`
  // width: 260px;
  height: 143px;
  background: #671e78;
  border-radius: 20px;
  display: flex;
  padding: 20px;
`;
const Icon = styled.div`
  width: 30%;
  display: flex;
  font-size: 30px;
  color: #5e9c08;
  justify-content: center;
  align-items: center;
  // border:2px solid red;
`;
const CardDetails = styled.div`
  width: 70%;
  bord/er: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = (props) => {
  const { icon, value, caption } = props;
  return (
    <Cards>
      <Icon>
        <div
          style={{
            height: "70px",
            width: "70px",
            background: "#fff",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </div>
      </Icon>
      <CardDetails>
        <p style={{ fontSize: "16px", color: "#ffffffb3" , fontFamily:'Gilroy-Bold'}}>{caption}</p>
        <h2
          style={{
            color: "#fff",
            marginTop: "15px",
            fontFamily: "Gilroy-Regular",
            fontSize: "50px",
          }}
        >
          {value}
        </h2>
      </CardDetails>
    </Cards>
  );
};

export default Card;
