import React from "react";
import { connect } from "react-redux";
import { Typography } from "@mui/material";

const WelcomeText = ({ user, pLoading }) => {
  return (
    <Typography variant="p" sx={{ lineHeight: "23px", fontSize: "20px" }}>
      Hi {user?.studentName ? `${user.studentName}!` : pLoading ? "" : ""}{" "}
      Welcome
    </Typography>
  );
};

const mapStateToProps = (state) => ({
  user: state.userState.user,
  pLoading: state.userState.loading,
});

export default connect(mapStateToProps)(WelcomeText);
