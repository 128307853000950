/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import {
  ChatBox,
  ChatBoxDiv,
  ChatDiv,
  FormFlexDiv,
  ImageDiv,
  ProfileImgDiv,
  TabContentWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import PropTypes from "prop-types";

import profileImg from "../../assets001/defaultpfp.png";
import DownArrow from "../../assets001/icons/down-arrow";
import colors from "../../assets001/colors.json";
import { MdSend } from "react-icons/md";

const Comments = ({
  message,
  pushComment,
  setMessage,
  trails,
  studentId,
  formLoading,
}) => {
  const trackEnterKey = (e) => {
    if (e.key === "Enter") {
      if (message.length > 0) {
        pushComment();
      }
    }
  };

  console.log("traillxx", trails);

  // Find the first comment by a teacher
  const teacherComment = trails.find((item) => item.author === "T");

  // Extract teacher name and image, use defaults if not found
  const teacherName = teacherComment ? teacherComment.teacherName : "Teacher";
  const teacherImage =
    teacherComment && teacherComment.teacherImage
      ? teacherComment.teacherImage
      : profileImg;

  const commentSubmitForm = () => {
    if (message.length > 0) {
      pushComment();
    }
  };

  useEffect(() => {
    if (trails.length > 0) {
      const divElement = document.querySelector(".move-down");
      divElement.style.backgroundColor = "red";
      divElement.scrollTop = divElement.scrollHeight;
    }
  }, [trails]);

  return (
    <TabContentWrapper>
      <ChatDiv>
        <FormFlexDiv width="30%">
          <ProfileImgDiv>
            <ImageDiv>
              <img src={teacherImage} alt="profileImg" />
              <span></span>
            </ImageDiv>
            <p>{teacherName}</p>
          </ProfileImgDiv>
        </FormFlexDiv>
        <FormFlexDiv width="70%">
          <ChatBoxDiv className="move-down">
            {trails.length > 0 ? (
              trails.map((item, index) => {
                const outbox =
                  item.studentId == studentId || item.studid == studentId;
                const isStudent = item.author === "S";
                return (
                  <div
                    className={`flex flex-col mt-0.5 ${
                      outbox ? "items-end" : ""
                    }`}
                    key={index}
                  >
                    {!outbox &&
                      (isStudent ? (
                        <div className="flex gap-1 items-center mt-1">
                          <img
                            src={item.studentImage || profileImg}
                            className="h-5 w-5 rounded-full"
                          />
                          <span className="text-xs ">{item.studentName}</span>
                        </div>
                      ) : (
                        <div className="flex gap-1 items-center mt-1">
                          <img
                            src={item.teacherImage || profileImg}
                            className="h-5 w-5 rounded-full"
                          />
                          <span className="text-xs ">{item.teacherName}</span>
                        </div>
                      ))}

                    <ChatBox
                      msgType={
                        item.studentId == studentId || item.studid == studentId
                          ? "outbox"
                          : "inbox"
                      }
                      isStudent={isStudent}
                    >
                      <div>
                        {item.comment}
                        {/* <span>
                          <DownArrow
                            color={
                              item.studentId == studentId ||
                              item.studid == studentId
                                ? colors.primary
                                : "white"
                            }
                          />
                        </span> */}
                      </div>
                    </ChatBox>
                  </div>
                );
              })
            ) : (
              <div>No Messages at the moment</div>
            )}

            {/* <ChatBox msgType="outbox">
              <div>
                Hello From a Student of lasmab
                <span>
                  <DownArrow color={colors.primary} />
                </span>
              </div>
            </ChatBox>
            <ChatBox msgType="inbox">
              <div>
                I commented on Sigma, I want to add some fancy icons. Do you
                have any icon set?
                <span>
                  <DownArrow />
                </span>
              </div>
            </ChatBox>
            <ChatBox msgType="inbox">
              <div>
                I commented on Sigma, I want to add some fancy icons. Do you
                have any icon set?
                <span>
                  <DownArrow />
                </span>
              </div>
            </ChatBox>
            <ChatBox msgType="outbox">
              <div>
                Hello From a Student of lasmab
                <span>
                  <DownArrow color={colors.primary} />
                </span>
              </div>
            </ChatBox> */}
          </ChatBoxDiv>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <input
              type="text"
              value={message}
              disabled={formLoading}
              onKeyDown={(e) => trackEnterKey(e)}
              onChange={(e) => setMessage(e.target.value)}
              style={{
                borderRadius: "20px",
              }}
            />

            <button
              disabled={formLoading}
              type="button"
              style={{
                background: colors.primary,
                padding: "16px",
                fontSize: "25px",
                borderRadius: "50%",
                border: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={commentSubmitForm}
            >
              <MdSend style={{ color: "white" }} />
            </button>
          </div>
        </FormFlexDiv>
      </ChatDiv>
    </TabContentWrapper>
  );
};

Comments.propTypes = {
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};
export default Comments;
