import { Button, Grid, IconButton, ToggleButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import "./Quiz.css";
import antelope from "../../assets001/images/antelope.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Checkbox from "@mui/material/Checkbox";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily: "Gilroy-Regular",
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
  },
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  borderRadius:'20px',
height:'600px',
display:'flex',
flexDirection:'column',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Quiz = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container>
          <Grid
            item
            lg={8}
            sx={{ display: "flex", justifyContent: "flex-end", pr: 4, pt: 6 }}
          >
            <Box sx={{ width: "85%" }}>
              <Typography variant="h1">Quiz</Typography>
              <Box
                className="Quiz_banner"
                sx={{
                  borderRadius: "20px",
                  width: "100%",
                  p: 3,
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#fff",
                  }}
                >
                  <Grid item lg={8}>
                    <Typography variant="h4">Chemistry</Typography>
                    <Typography
                      variant="p"
                      sx={{ fontFamily: "gilroy-regular", fontSize: "14px" }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Cursus aliquet mi vitae faucibus pellentesque mattis elit.
                      Purus, matt
                    </Typography>

                    <Box
                      sx={{
                        fontSize: "13px",
                        fontFamily: "gilroy-regular",
                        mt: 3,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AccessTimeIcon sx={{ fontSize: "16px", mr: 1 }} />
                      <Typography variant="p"> 20 mins</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item={2}
                    sx={{
                      fontFamily: "gilroy-regular",
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "right",
                    }}
                  >
                    <Typography variant="body">Total question: 30</Typography>
                    <Button
                      variant="contained"
                      sx={{
                        background: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "20px",
                      }}
                    >
                      Practice
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={5} sx={{}}>
                <Grid container>
                  <Grid item xs={12} sx={{ display: "flex" }}>
                    <Typography variant="h2">1.</Typography>
                    <Box ml={2}>
                      <Typography variant="h4">
                        What effect being used in this video
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                      >
                        (Select multiple answers)
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} pl={3}>
                    <ol type="A" style={{ fontFamily: "Gilroy-Regular" }}>
                      <li style={{}}>
                        <Checkbox
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: "#5E9C08",
                            },
                          }}
                        />
                        Lorem ipsum vrg nghh{" "}
                      </li>
                      <li style={{}}>
                        <Checkbox
                          checked
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: "#5E9C08",
                            },
                          }}
                        />
                        Lorem ipsum vrg nghh{" "}
                      </li>
                      <li style={{}}>
                        <Checkbox
                          checked
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: "#5E9C08",
                            },
                          }}
                        />
                        Lorem ipsum vrg nghh{" "}
                      </li>
                      <li style={{}}>
                        <Checkbox
                          checked
                          sx={{
                            color: "#000",
                            "&.Mui-checked": {
                              color: "#5E9C08",
                            },
                          }}
                        />
                        Lorem ipsum vrg nghh{" "}
                      </li>
                    </ol>
                  </Grid>
                  <Grid item xs={8} pl={4} sx={{ borderRadius: "3px" }}>
                    <img src={antelope} alt="question Image" width="100%" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      sx={{ fontFamily: "Gilroy-regular" }}
                    >
                      Next
                      <TrendingFlatIcon sx={{ ml: 2 }} />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* ////////////.........................SIDE.......................///////////////// */}
          <Grid
            item
            lg={4}
            sx={{
              height: "100vh",
              background:
                "linear-gradient(90deg, #CFD0CD 4.61%, rgba(249, 249, 249, 0) 200.1%)",
              borderRadius: "20px 0px 0px 20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "273px",
                height: "68px",
                background: "#fff",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="p" sx={{ fontFamily: "Gilroy-Regular" }}>
                Time Left: 25:00
              </Typography>
            </Box>
            <Box mt={9}>
              <Grid
                container
                spacing={3}
                sx={{ margin: "0 auto", width: "80%" }}
              >
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button variant="contained">1</Button>
                  <Button variant="contained">2</Button>
                  <Button variant="contained">3</Button>
                  <Button variant="contained">4</Button>
                  <Button variant="contained">5</Button>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    6
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    7
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    8
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    9
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    10
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    11
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    12
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    13
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    14
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    15
                  </Button>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    16
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    17
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    18
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    19
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#fff", color: "#000" }}
                  >
                    20
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box mt={10} sx={{ textAlign: "center" }}>
              <Typography variant="body" sx={{ fontFamily: "Gilroy-Regular" }}>
                Answered question
              </Typography>
              <Typography variant="h4" sx={{ color: "#5E9C08" }}>
                3 of 6
              </Typography>
            </Box>
            <Box mt={8}>
              <Button variant="contained" sx={{ p: "5px 80px" }} onClick={handleOpen}>
                Submit Test
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

     {/* ////////////.........................MODAL......................///////////////// */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Box sx={{textAlign:'right'}}>
          <IconButton>

          <HighlightOffIcon  onClick={handleClose}/>
          </IconButton>
         </Box>
         <Box  sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%',flexDirection:'column'}}>
<Typography variant="h5">Test Completed</Typography>
<Typography variant="body1" sx={{color:'#671E78'}}>Congratulations!</Typography>
<Box  mb={4} mt={4} sx={{width:'80%', height:'200px',bgcolor:'rgba(103, 30, 120, 0.1)', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'20px', flexDirection:'column' }}>
  <Typography variant="body1">Your score is</Typography>
  <Typography variant="h4">83%</Typography>
</Box>
<Button variant="contained" sx={{width:'80%'}}>Okay</Button>
         </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Quiz;
