/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, createRef } from "react";
import colors from "../../assets001/colors.json";
import parse from "html-react-parser";

import { useMediaQuery } from "react-responsive";
import {
  TabContentWrapper,
  FormFlex,
  FormFlexDiv,
  QuestionContainer,
  QuestionLink,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import Loader from "../pageLoader/loader";

import {
  AuthButton,
  InputWrapper,
  ButtonFlex,
} from "../../pages/auth/auth.style";
import FormLoader from "../FormLoader";
import { useParams } from "react-router-dom";

const Submission = ({
  uploadAnswerFunc,
  callSubmit,
  formLoading,
  questions,
  question,
  setQuestion,
  loading,
  actionType,
  fileObj,
  setFileObj,
  classworks,
}) => {
  const { slug } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const showQuestion = (id) => {
    let obj = questions.find((item) => item.questionId === id);
    setQuestion(obj);
  };
  const onPickFileRef = createRef();
  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    if (event !== null) {
      const files = event.target.files;
      console.log(files);
      // setFileObj({ ...fileObj, name: files[0].name });
      let filename = files[0].name;
      if (
        filename.includes(".pdf") ||
        filename.includes(".doc") ||
        filename.includes(".docx") ||
        filename.includes(".jpg") ||
        filename.includes(".jpeg") ||
        filename.includes(".png")
      ) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          setFileObj({
            ...fileObj,
            content: fileReader.result,
            name: filename,
          });

          // setBase64(fileReader.result);
        });
        fileReader.readAsDataURL(files[0]);
      } else {
        alert("Please upload a document");
      }

      console.log("We are working");
    }
  };

  const handleLinkClick = (e, dataUrl, fileName) => {
    e.preventDefault();

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = fileName;

    // Trigger a click event on the anchor element
    link.click();
  };

  const numericSlug = parseInt(slug, 10);
  const classWork = classworks?.find(
    (each) => each.classWorkId === numericSlug
  );

  const disableSubmission = () => {
    const submitted = questions.find(
      (question) => question?.submitStatus === 1
    );
    return submitted || classWork?.status === 2 ? true : false;
  };

  return (
    <TabContentWrapper pd="0rem">
      <div style={{ marginTop: "05px" }}>
        {loading ? (
          <Loader />
        ) : questions.length > 0 ? (
          <>
            <QuestionContainer>
              {questions.map((item, index) => (
                <QuestionLink
                  key={index}
                  isActive={
                    question !== null && question.questionId === item.questionId
                  }
                  onClick={() => showQuestion(item.questionId)}
                >{`Question ${index + 1}`}</QuestionLink>
              ))}
            </QuestionContainer>
            <div style={{ padding: "0.4rem 0.4rem" }}>
              {question !== null && (
                <>
                  <div
                    style={{
                      margin: "10px 0px",
                      padding: "0.2rem 0.4rem",
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                    {questions.findIndex(
                      (i) => i.questionId == question.questionId
                    ) + 1}
                    . {parse(question.question)}
                  </div>

                  {question.donloadFilePath &&
                    question.donloadFilePath.length > 0 &&
                    question.donloadFilePath.startsWith(
                      "https://res.cloudinary.com"
                    ) && (
                      <img
                        src={question.donloadFilePath}
                        alt="imagee"
                        style={{
                          objectFit: "contain",
                          maxHeight: "200px",
                          maxWidth: "80%",
                        }}
                      />
                    )}

                  <FormFlex>
                    <FormFlexDiv width="100%">
                      <InputWrapper>
                        <label htmlFor="">Answer for {`Question Above`}</label>
                        <textarea
                          disabled={question.submitStatus === 1}
                          cols="30"
                          rows="3"
                          value={question.submittedAnswer}
                          onChange={(e) => {
                            setQuestion({
                              ...question,
                              submittedAnswer: e.target.value,
                            });
                            // setAnswer(e.target.value);
                          }}
                        ></textarea>
                      </InputWrapper>
                    </FormFlexDiv>
                  </FormFlex>
                </>
              )}

              <ButtonFlex
                style={{
                  justifyContent: "end",
                  borderBottom: `6px solid ${colors.primary}`,
                  paddingBottom: "0.8rem",
                }}
              >
                {question !== null && (
                  <>
                    {!disableSubmission() && (
                      <AuthButton
                        disabled={
                          formLoading || question.submittedAnswer.length === 0
                        }
                        width={isMobile ? "100%" : "25%"}
                        style={{
                          marginLeft: isMobile ? "0px" : "5px",
                        }}
                        onClick={() => callSubmit("Save")}
                      >
                        {formLoading && actionType === "Save" ? (
                          <FormLoader />
                        ) : question.submitStatus === 0 ? (
                          <>Update Answer</>
                        ) : (
                          <>Save Answer</>
                        )}
                      </AuthButton>
                    )}
                  </>
                )}
              </ButtonFlex>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <div style={{ width: isMobile ? "100%" : "70%" }}>
                  <input
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={onPickFile}
                    ref={onPickFileRef}
                  />
                  <InputWrapper>
                    <label htmlFor="filename">Upload File </label>
                    <input
                      style={{
                        borderRadius: isMobile
                          ? "10px 10px 10px 10px"
                          : "10px 0px 0px 10px",
                        height: "50px",
                      }}
                      type="text"
                      value={fileObj.name}
                      disabled={true}
                      placeholder="Upload File Containing Answers To All Questions"
                    />
                  </InputWrapper>
                </div>
                <AuthButton
                  onClick={pickFile}
                  style={{
                    marginTop: isMobile ? "10px" : "0px",
                    height: "50px",
                    borderRadius: isMobile
                      ? "10px 10px 10px 10px"
                      : "0px 10px 10px 0px",
                    background: colors.secondary,
                    borderColor: colors.secondary,
                    borderLeft: isMobile ? "" : "none",
                  }}
                  padding="0px"
                  width={isMobile ? "100%" : "30%"}
                  color="black"
                >
                  upload File
                </AuthButton>
              </div>

              {fileObj.name.length > 0 && fileObj.content.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.2rem",
                    marginBottom: "0.4rem",
                  }}
                >
                  <a
                    // href={fileObj.content}
                    href="#"
                    onClick={(e) =>
                      handleLinkClick(e, fileObj.content, fileObj.name)
                    }
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                    rel="noreferrer"
                  >
                    View File
                  </a>
                  <span
                    onClick={() =>
                      setFileObj({ ...fileObj, name: "", content: "" })
                    }
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Delete File
                  </span>
                </div>
              )}

              {fileObj.name.length > 0 && fileObj.content.length > 0 && (
                <AuthButton
                  disabled={formLoading}
                  width={"100%"}
                  style={{
                    marginBottom: "0.8rem",
                  }}
                  onClick={uploadAnswerFunc}
                >
                  {formLoading ? <FormLoader /> : <>Upload File Answer</>}
                </AuthButton>
              )}
              {question !== null && (
                <>
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginTop: "0.5rem",
                    }}
                  >
                    Please note that once you click "Submit," you will not be
                    able to save your answer for any of the questions above.
                  </span>
                  <AuthButton
                    disabled={
                      formLoading ||
                      question.submittedAnswer.length < 1 ||
                      question.submitStatus === 1 ||
                      disableSubmission()
                    }
                    width={"100%"}
                    style={{
                      marginTop: "0.1rem",
                    }}
                    onClick={() => callSubmit("submit")}
                  >
                    {formLoading && actionType === "submit" ? (
                      <FormLoader />
                    ) : (
                      <>
                        {question.submitStatus === 1
                          ? "Submitted"
                          : " Submit Answer"}
                      </>
                    )}
                  </AuthButton>
                </>
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.primary,
            }}
          >
            No Questions At The Moment
          </div>
        )}
      </div>
    </TabContentWrapper>
  );
};

export default Submission;
