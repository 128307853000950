import {
  MdOutlineDashboard,
  MdLiveTv,
  MdOutlineMedicalServices,
  MdComputer,
  MdOutlineVideoSettings,
  MdOutlineSchool,
  MdEditNote,
  MdOutlineEventNote,
} from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { BsChatSquareDots, BsJournalBookmark } from "react-icons/bs";

export const SidebarData = [
  {
    id: 1,
    label: "Dashboard",
    link: "/dashboard",
    icon: <MdOutlineDashboard />,
  },
  {
    id: 2,
    label: "Profile",
    link: "/profile",
    icon: <BiUser />,
  },
  {
    id: 3,
    label: "Result",
    link: "/result",
    icon: <MdOutlineSchool />,
  },
  {
    id: 3,
    label: "Classwork",
    link: "/classwork",
    icon: <MdOutlineEventNote />,
  },
  {
    id: 3,
    label: "Assignment",
    link: "/assignment",
    icon: <MdEditNote />,
  },
  {
    id: 3,
    label: "Tutorial/Resources",
    link: "/tutorial",
    icon: <MdOutlineVideoSettings />,
  },
  {
    id: 3,
    label: "CBT",
    link: "/cbt",
    icon: <MdComputer />,
  },
  {
    id: 3,
    label: "Chat",
    link: "/chat",
    icon: <BsChatSquareDots />,
  },
  {
    id: 3,
    label: "Live Class",
    link: "/live-class",
    icon: <MdLiveTv />,
  },
  {
    id: 3,
    label: "Medical",
    link: "/medical",
    icon: <MdOutlineMedicalServices />,
  },
  {
    id: 3,
    label: "Ledger",
    link: "/ledger",
    icon: <BsJournalBookmark />,
  },
];
