import React from "react";
import {
  Container,
  FormFlex,
  FormFlexDiv,
  BigHeader,
  SmallHeader,
  ProfileImgDiv,
  ImageDiv,
  AccordionText,
  ChatDiv,
  ChatBox,
  ChatBoxDiv,
  Input,
  Icon,
  Search,
} from "./styles/dashboard.style";
import GlobalStyles from "../../assets001/globalStyle";
import profileImg from "../../assets001/avatar.jpeg";
import DownArrow from "../../assets001/icons/down-arrow";
import colors from "../../assets001/colors.json";
import WelcomeText from "../../components/WelcomeText";

const Chats = () => {
  return (
    <Container>
      <GlobalStyles />
      <div>
        <FormFlexDiv width="100%" style={{ borderBottom: "2px solid #671E78", marginBottom:'20px' }}>
          <WelcomeText />

          <BigHeader>Chat</BigHeader>
        </FormFlexDiv>
        <FormFlexDiv
          width="70%"
          style={{
            display: "inline-flex",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <ProfileImgDiv bg="white" hideBorder={true}>
                <ImageDiv>
                  <img src={profileImg} alt="lol" />
                  <span></span>
                </ImageDiv>
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <p>John Doe</p>
                  <p
                    style={{
                      fontSize: "13px",
                      color: "rgba(32,30,30, 0.85)",
                    }}
                  >
                    online
                  </p>
                </div>
              </ProfileImgDiv>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AccordionText>Messages</AccordionText>
            </div>
          </div>
        </FormFlexDiv>
      </div>{" "}
      <ChatDiv>
        <FormFlexDiv width="30%">
          <ProfileImgDiv bg="white" hideBorder={true}>
            <ImageDiv>
              <img src={profileImg} alt="profileImg" />
              <span></span>
            </ImageDiv>
            <div
              style={{
                marginLeft: "10px",
                flex: "1",
              }}
            >
              <p>Jane Doe</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(32,30,30, 0.85)",
                  }}
                >
                  Hello I'm on my way....
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(32,30,30, 0.85)",
                  }}
                >
                  2H
                </p>
              </div>
            </div>
          </ProfileImgDiv>
          <ProfileImgDiv>
            <ImageDiv>
              <img src={profileImg} alt="profileImg" />
              <span></span>
            </ImageDiv>
            <div
              style={{
                marginLeft: "10px",
                flex: "1",
              }}
            >
              <p>John Doe</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(32,30,30, 0.85)",
                  }}
                >
                  Hello I'm on my way....
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(32,30,30, 0.85)",
                  }}
                >
                  2H
                </p>
              </div>
            </div>
          </ProfileImgDiv>
        </FormFlexDiv>
        <FormFlexDiv width="70%">
          <ChatBoxDiv bg="white">
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "400",
                color: "#7C6CA8",
              }}
            >
              <p>1 FEB 12:00</p>
            </div>
            <ChatBox msgType="inbox">
              <div>
                I commented on Sigma, I want to add some fancy icons. Do you
                have any icon set?
                <span>
                  <DownArrow />
                </span>
              </div>
            </ChatBox>
            <ChatBox msgType="outbox" bg="rgba(94, 156, 8, 0.5)" color="white">
              <div>
                okay
                <span>{/* <DownArrow color={colors.primary} /> */}</span>
              </div>
            </ChatBox>
            <ChatBox msgType="outbox" bg="rgba(94, 156, 8, 0.5)" color="white">
              <div>
                Hello From a Student of lasmab
                <span>{/* <DownArrow color={colors.primary} /> */}</span>
              </div>
            </ChatBox>
          </ChatBoxDiv>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <Input style={{ flex: 1 }}>
              <Icon>
                {/* <span
                  style={{
                    background: "grey",
                    padding: "8px",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                  }}
                ></span> */}
              </Icon>
              <Search type="text" placeholer="search" />
            </Input>
            <button
              style={{
                background: "white",
                padding: "7px 20px",
                // fontSize: "24px",
                borderRadius: "25px",
                // border: "none",
                marginLeft: "5px",
                fontSize: "13px",
                fontWeight: "bold",
                minWidth: "66px",
                color: "black",
                border: `1px solid ${colors.primary}`,
              }}
            >
              {/* <MdSend style={{ color: "white" }} /> */}
              Send
            </button>
          </div>
        </FormFlexDiv>
      </ChatDiv>
    </Container>
  );
};

export default Chats;
