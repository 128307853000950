/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { AccountSummaryWrapper } from "../../pages/student-Dashboard/styles/dashboard.style";
import { useMediaQuery } from "react-responsive";
import colors from "../../assets001/colors.json";
import { getStudentMedicalInfo } from "../../actions/user";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../pageLoader/loader";
import moment from "moment";
const InOutRecord = ({ getStudentMedicalInfo }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [bioData, setBioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentMedicalInfo(
        token,
        userInstance.studentId,
        userInstance.schoolId,
        "in-out-record"
      );
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }

      console.log(res);
    } catch (error) {
      toast.error("An Error Occured! Try Again Later");
      setLoading(false);
    }
  }, [
    getStudentMedicalInfo,
    token,
    userInstance.schoolId,
    userInstance.studentId,
  ]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AccountSummaryWrapper pt="0px">
          <table
            style={{
              borderRadius: "0px",
            }}
          >
            <tr
              className="table-head"
              style={{
                backgroundColor: "#F0E8F1",
                color: "black",
              }}
            >
              <th>Name</th>
              <th>Date</th>
              {!isMobile && <th>Class</th>}

              <th>House</th>
              <th>Guardian's Phone Number</th>
              <th>Time out</th>

              {!isMobile && <th>Signature</th>}
              {!isMobile && <th>Remark</th>}
            </tr>
            {bioData.length > 0 ? (
              bioData.map((item, index) => (
                <tr style={{ border: "1px solid #F0E8F1" }} key={index}>
                  <td>{item.name}</td>
                  <td>{moment(item.date).format("YYYY/MM/DD")}</td>
                  {/* <td>Dr Pan Peter</td> */}
                  {!isMobile && <td>{item.class}</td>}
                  <td>{item.house}</td>
                  <td>{item.guardianPhone}</td>
                  <td>{moment(item.data).format("LT")}</td>
                  {!isMobile && <td>{item.signature}</td>}
                  {!isMobile && <td>{item.remark}</td>}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={!isMobile ? 8 : 5}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  No Drug Usage For This Student
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      )}
    </>
  );
};

export default connect(null, { getStudentMedicalInfo })(InOutRecord);
