/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TabContainer,
  TabLinkContainer,
  TabLink,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import { getClassworkList } from "../../actions/user";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../pageLoader/loader";
import CList from "./CList";
const ClassworkParent = ({ getClassworkList }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const token = localStorage.lasmabAuthToken || "";

  const [loading, setLoading] = useState(true);
  const [obj, setObj] = useState(null);
  const getData = async () => {
    setLoading(true);
    try {
      let res = await getClassworkList(
        token,
        userInstance.studentId,
        userInstance.schoolId
      );
      if (res.status === true && res.statusCode === 2) {
        setObj(res.data);
        setLoading(false);
      } else {
        toast.error(res.data.Message);
        setLoading(false);
        console.log("something is wrong");
      }
    } catch (err) {
      setLoading(false);
      toast.error("An Error Occured");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [tab, setTab] = useState(1);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TabContainer>
        <TabLinkContainer>
          <TabLink
            isActive={tab === 1}
            onClick={() => setTab(1)}
            minWidth={"50%"}
          >
            Pending Classworks
          </TabLink>
          <TabLink
            isActive={tab === 2}
            onClick={() => setTab(2)}
            minWidth={"50%"}
          >
            Submitted Classworks
          </TabLink>
        </TabLinkContainer>

        {tab === 1 && (
          <CList getData={getData} allData={obj?.pendingClassworkList} />
        )}
        {tab === 2 && (
          <CList getData={getData} allData={obj?.attenptedClassworkList} />
        )}
      </TabContainer>
    </>
  );
};

export default connect(null, { getClassworkList })(ClassworkParent);
