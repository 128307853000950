import React, { useState, useEffect, useCallback } from "react";
import {
  AuthRightOverlay,
  AuthRightWrapper,
  CarouselDots,
  CarouselWrapper,
  RightLogoWrapper,
  RigthBigText,
} from "../pages/auth/auth.style";
import logo from "../assets001/school-logo.png";
import img1 from "../assets001/images/img_one.jpeg";
import img2 from "../assets001/images/img_two.jpeg";
import img3 from "../assets001/images/img_three.jpeg";
import img4 from "../assets001/images/img_four.jpeg";
import ArrowRight from "../assets001/icons/arrow-right-circle";
let data = [img1, img2, img3, img4];
const RightSide = () => {
  const [selected, setSelected] = useState(2);
  const nextSlide = useCallback(() => {
    let num = selected;
    num++;
    if (data.length - 1 === selected) {
      setSelected(0);
    } else {
      setSelected(num);
    }
  }, [selected]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 60 * 1 * 1000);

    return () => clearInterval(interval);
  }, [selected, nextSlide]);

  return (
    <AuthRightWrapper bgUrl={data[selected]}>
      <AuthRightOverlay />
      <RightLogoWrapper>
        <img src={logo} alt="" />
      </RightLogoWrapper>
      <RigthBigText>
        Welcome Back, Students! Log in to unlock a world of knowledge and
        connections. Your learning journey begins here.
      </RigthBigText>

      <CarouselWrapper>
        <div
          style={{
            display: "flex",
          }}
        >
          {data.map((item, index) => {
            return (
              <CarouselDots
                key={index}
                indexSelected={index === selected}
                onClick={() => setSelected(index)}
              />
            );
          })}
        </div>

        <div
          onClick={nextSlide}
          style={{
            cursor: "pointer",
          }}
        >
          <ArrowRight />
        </div>
      </CarouselWrapper>
    </AuthRightWrapper>
  );
};

export default RightSide;
