import React from "react";
import { BigHeader, Container, SmallHeader } from "./styles/dashboard.style";
import GlobalStyles from "../../assets001/globalStyle";
import { Outlet } from "react-router-dom";
import WelcomeText from "../../components/WelcomeText";
const Assignment = () => {
  return (
    <Container>
      <GlobalStyles />
      <div style={{ borderBottom: "2px solid #671E78", marginBottom:'40px' }}>
        <WelcomeText />
        {/* <SmallHeader>Hi there! Welcome</SmallHeader> */}
        <BigHeader>Assignment</BigHeader>
      </div>
      <Outlet />
    </Container>
  );
};

export default Assignment;
