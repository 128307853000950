import React, { useState } from "react";
// import styled from "styled-components";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography, Button, Divider, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { HighlightAltRounded } from "@mui/icons-material";
// import SearchIcon from '@mui/icons-material/Search';

let theme = createTheme({
  palette: {
    primary: {
      main: "#671E78",
    },
    secondary: {
      main: "#edf2ff",
    },
  },
  typography: {
    fontFamily:'Gilroy-Regular',
    h1: {
      fontFamily: "Gilroy-Bold",
      fontSize: "52px",
    },
 
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "58%",
  transform: "translate(-50%, -50%)",
  width: '570px',
  height: 600,
  bgcolor: "background.paper",
borderRadius:'20px',
  boxShadow: 24,
  
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
fontFamily:'Gilroy-Regular',
    "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
    
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(filename, uploadDate,) {
  return { filename, uploadDate,  };
}

const rows = [
  createData( "Introduction to organic chemistry","2021/2022", ),
  createData( "Introduction to organic chemistry","2021/2022", ),
  createData( "Introduction to organic chemistry","2021/2022", ),


];
const Resources = () => {
  const [open, setOpen] =useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{ fontFamily: "gilroy-regular", margin: "0 auto", width: "90%" }}
        >
          {/* .......,,,,,,,,,,,,,,.............Tables..............,,,,,,,,,,,,,,,,............... */}
          <Box
            mt={5}
            sx={{
              border: "1px solid #671E78",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                borderRadius: "20px 20px 0px 0px",
                pt: 2,
                background: "rgba(103, 30, 120, 0.1)",
             
                pl: { xs: 2.5 },
              }}
            >
          
            </Box>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead
                  sx={{
                    backgroundColor: "rgba(103, 30, 120, 0.1)",
                    width: "100%",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S/N</StyledTableCell>
                    <StyledTableCell>Filename</StyledTableCell>
                    <StyledTableCell>Upload Date</StyledTableCell>
                   
                 
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
                  {rows.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{row.filename}</StyledTableCell>
                      <StyledTableCell>{row.uploadDate}</StyledTableCell>
                   
                      <StyledTableCell>
                        <Button
                          variant="outlined"
                          onClick={handleOpen}
                          sx={{
                            borderRadius: "5px",
                            fontFamily: "gilroy-regular",
                            px: 4,
                          }}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
          </Box>
          <Box mt={16} sx={{display:'flex', justifyContent:'flex-end'}}>
            <Box>
                <Link to="/tutorial">
                <Button variant="outlined" sx={{color:'#000', mr:4}}>Back</Button></Link>

                <Button variant="contained" >Download Resources</Button>
            </Box>
          </Box>
        </Box>

        {/* ............,,,,,,,,///////////////////................MODAL.............//////////////// */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{textAlign:'right'}}>
            <IconButton sx={{ml:'auto'}} onClick={handleClose}>
                          <HighlightOffIcon />
                </IconButton>
            </Box>
            <Box sx={{p:4}}>
                <Typography variant="h5" sx={{fontFamily:'Gilroy-Bold', fontSize:'24px', textAlign:'center'}}>Tutorial/ Resources</Typography>
              
            </Box>
            
            <Divider sx={{mb:2}}/>
            <Box sx={{height:350, background:'rgba(217, 217, 217, 0.1)', border:'1px solid rgba(103, 30, 120, 0.1)', borderRadius:'20px', margin:'0 auto', width:'80%'}}>

            </Box>
            <Box sx={{display:'flex', justifyContent:'center', mt:5}}>

            <Button variant="contained" >Download Resources</Button>
            </Box>
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default Resources;
