import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets001/school-logo-no-bg.png";
import { createGlobalStyle } from "styled-components";
import { SidebarData } from "../../assets001/Data/SidebarData";
import { Link, useLocation } from "react-router-dom";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
// import { useEventListener } from "usehooks-ts";
import {
  AppBar,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
// import { Box } from "@mui/system";

const GlobalStyle = createGlobalStyle`
.p{
  font-family:Gilroy-Regular;
  font-size:14px;
  color:#ffffff;
  opacity:0.4;
  margin-left:40px;
}
a{
  color:white;
  font-family:Gilroy-Regular;
  text-decoration:none;
  opacity:0.4;
  &:hover {
    color:white;
    opacity:1;
  }
}
`;

const SidebarWrapper = styled.div`
  width: 260px;
  background: #671e78;

  position: fixed;
  animation: sidebar-slide-right 0.8s;

  padding-bottom: 20px;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // z-index: 1020;
`;
const LogoContainer = styled.div`
  height: 85px;
  margin-top: 50px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;
const Logo = styled.img`
  object-fit: cover;
  width: 80px;
  margin-left: 4px;
`;
const NavContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  overflow-y: scroll;
  height: 70vh;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Button = styled.button`
  padding: 10px 30px;
  font-family: Gilroy-Regular;
  color: #671e78;
  margin-top: 40px;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 3px;
`;

const DashboardSidebar = ({ controlNav, isOpen }) => {
  const router = useLocation();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down(992));
  const [up, setUp] = useState(false);
  const [show, setShow] = useState(true);

  // const scrollToTop = () => {
  //   containerRef.current.scrollTop = 0;
  //   setUp(false)
  // };

  // const changeColor = () => {

  //   if (containerRef.current.scrollTop === containerRef.current.scrollHeight) {
  //     setUp(true);
  //   } else {
  //     setUp(false);
  //   }
  // };

  // useEventListener('scroll', changeColor, containerRef)

  // window.containerRef.addEventListener("scroll", changeColor);

  const [scrollPosition, setScrollPosition] = useState(0);
  const targetDivRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = targetDivRef.current;
    const position = Math.max(0, scrollHeight - clientHeight - scrollTop);
    setScrollPosition(position);
  };

  const isContentOverflowing = () => {
    if (targetDivRef.current.scrollHeight > targetDivRef.current.clientHeight) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const scrollToBottom = () => {
    targetDivRef.current.scrollTo({
      top: targetDivRef.current.scrollHeight,
      behavior: "smooth",
    });
    setUp(true);

    // console.log('Is content overflowing:', isContentOverflowing);
  };

  const scrollToTop = () => {
    targetDivRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setUp(false);
  };

  useEffect(() => {
    if (!isMatch) {
      isContentOverflowing();
      const targetDiv = targetDivRef.current;
      targetDiv.addEventListener("scroll", handleScroll);
      return () => {
        targetDiv.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  useEffect(() => {
    // if (isMatch) {
    if (scrollPosition > 0) {
      setUp(false);
    } else {
      setUp(true);
    }
    // }
  }, [scrollPosition]);
  return (
    <>
      {isMatch ? (
        <Box></Box>
      ) : (
        <AppBar>
          <SidebarWrapper>
            <GlobalStyle />

            <LogoContainer>
              <Logo src={logo} />
            </LogoContainer>

            <Box sx={{ overflow: "hidden" }}>
              <NavContainer ref={targetDivRef}>
                <List>
                  {SidebarData.map((nav, id) => (
                    <Link to={nav.link} key={id} style={{ opacity: 1 }}>
                      <ListItemButton
                        selected={router.pathname === nav.link ? true : false}
                        sx={{
                          color: "white",
                          mr: 2,
                          mb: 1,
                          pl: 5,
                          fontFamily: "Gilroy-Regular",

                          " 	&.Mui-selected": {
                            bgcolor: "rgba(217, 217, 217, 0.1)",
                            borderLeft: "5px solid #fff",
                            color: "#fff",
                          },
                        }}
                      >
                        <ListItemIcon sx={{ color: "#fff", fontSize: "20px" }}>
                          {nav.icon}
                        </ListItemIcon>

                        <Typography sx={{ fontFamily: "Gilroy-Regular" }}>
                          {nav.label}
                        </Typography>
                      </ListItemButton>
                    </Link>
                  ))}
                </List>
              </NavContainer>
            </Box>

            {/* <Button>Parent Dashboard</Button> */}

            {show && (
              <>
                {up ? (
                  <Fab
                    align="right"
                    onClick={scrollToTop}
                    sx={{
                      background: "transparent",
                      "&:hover": { bgcolor: "transparent" },
                      width: "40px",
                      height: "40px",
                      position: "absolute",
                      bottom: "10px",
                      right: "20px",
                      transition: "5s all linear",
                    }}
                  >
                    <KeyboardDoubleArrowUpIcon
                      sx={{ color: "#fff", fontSize: "13px" }}
                    />
                  </Fab>
                ) : (
                  <Fab
                    align="right"
                    onClick={scrollToBottom}
                    sx={{
                      background: "transparent",
                      "&:hover": { bgcolor: "transparent" },
                      width: "40px",
                      height: "40px",
                      position: "absolute",
                      bottom: "10px",
                      right: "20px",
                      transition: "5s all linear",
                    }}
                  >
                    <KeyboardDoubleArrowDownIcon
                      sx={{ color: "#fff", fontSize: "13px" }}
                    />{" "}
                  </Fab>
                )}
              </>
            )}
            <p className="text-white mb-2">Last updated : 14th Oct, 2024 (9) </p>
          </SidebarWrapper>
        </AppBar>
      )}
    </>

    // correct
  );
};

export default DashboardSidebar;
