import React, { useState } from "react";
import {
  BigHeader,
  Container,
  SmallHeader,
  TabContainer,
  TabContent,
  TabLink,
  TabLinkContainer,
} from "./styles/dashboard.style";
import GlobalStyles from "../../assets001/globalStyle";
import OngoingSession from "../../components/LiveClass/OngoingSession";
import PreviousSession from "../../components/LiveClass/PreviousSession";
import WelcomeText from "../../components/WelcomeText";

const LiveChats = () => {
  const [tab, setTab] = useState(2);
  return (
    <Container>
      <GlobalStyles />
      <div
        style={{
          position: "relative",
          borderBottom: "2px solid #671E78", marginBottom:'40px'
        }}
      >
        <WelcomeText />
        <BigHeader>Live Class</BigHeader>
      </div>

      <TabContainer>
        <TabLinkContainer
          style={{
            justifyContent: "space-around",
          }}
        >
          <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
            Ongoing Session
          </TabLink>
          <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
            Previous Session
          </TabLink>
        </TabLinkContainer>
        {tab === 1 && (
          <TabContent>
            <OngoingSession />
          </TabContent>
        )}

        {tab === 2 && (
          <TabContent>
            <PreviousSession />
          </TabContent>
        )}
      </TabContainer>
    </Container>
  );
};

export default LiveChats;
