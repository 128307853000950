import { useState } from "react";
import {
  Container,
  BigHeader,
  TabContainer,
  TabLinkContainer,
  TabLink,
  TabContent,
} from "./styles/dashboard.style";
import GlobalStyles from "../../assets001/globalStyle";
import StudentBioData from "../../components/profile/StudentBioData";
import SocialData from "../../components/profile/SocialData";
import Attachments from "../../components/profile/Attachments";
import Health from "../../components/profile/Health";
import WelcomeText from "../../components/WelcomeText";

const Profile = () => {
  const [tab, setTab] = useState(1);

  return (
    <Container>
      <GlobalStyles />
      {/* Header Texts */}
      <div style={{ borderBottom: "2px solid #671E78" }}>
        <WelcomeText />
        <BigHeader>Profile</BigHeader>
      </div>
      {/* Info Message Icon */}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginBottom:'40px'
        }}
      >
    
      </div>
      <TabContainer >
        <TabLinkContainer>
          <TabLink isActive={tab === 1} onClick={() => setTab(1)}>
            <p>Student Bio-data</p>
          </TabLink>
          <TabLink isActive={tab === 2} onClick={() => setTab(2)}>
            <p>Social Data</p>
          </TabLink>
          <TabLink isActive={tab === 3} onClick={() => setTab(3)}>
            <p>Health Data</p>
          </TabLink>
          <TabLink isActive={tab === 4} onClick={() => setTab(4)}>
            <p>Attachments</p>
          </TabLink>
        </TabLinkContainer>
        {/* Student Bio Data */}

        <TabContent>
          {tab === 1 && <StudentBioData />}
          {tab === 2 && <SocialData />}
          {tab === 3 && <Health />}
          {tab === 4 && <Attachments />}
        </TabContent>
      </TabContainer>
    </Container>
  );
};

export default Profile;
