/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { createRef, useState, useEffect, useCallback } from "react";
import {
  AccountSummaryWrapper,
  AttachmentFlex,
  AttachmentUploadContainer,
  InlineFormDiv,
  ButtonFlex,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import moment from "moment";
import { toast } from "react-toastify";
import { AuthButton, InputWrapper } from "../../pages/auth/auth.style";
import { useMediaQuery } from "react-responsive";
import {
  getStudentProfileInfoData,
  uploadAttach,
  deleteAttach,
} from "../../actions/user";
import Loader from "../pageLoader/loader";
import colors from "../../assets001/colors.json";
import { connect } from "react-redux";
import { AiFillFileAdd } from "react-icons/ai";
import FormLoader from "../FormLoader";
const Attachments = ({
  getStudentProfileInfoData,
  uploadAttach,
  deleteAttach,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const [formLoading, setFormLoading] = useState(false);
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [bioData, setBioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const onPickFileRef = createRef();
  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState("");
  const [baseName, setBaseName] = useState("");
  const [delLoading, setDelLoading] = useState(false);
  const [id, setId] = useState("");

  const pickFile = () => {
    onPickFileRef.current.click();
  };
  const onPickFile = (event) => {
    const files = event.target.files;
    console.log(files);
    setBaseName(files[0].name);
    let filename = files[0].name;
    if (
      filename.includes(".pdf") ||
      filename.includes(".doc") ||
      filename.includes(".docx") ||
      filename.includes(".jpg") ||
      filename.includes(".jpeg") ||
      filename.includes(".png")
    ) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setBase64(fileReader.result);
      });
      fileReader.readAsDataURL(files[0]);
    } else {
      alert("Please upload a document");
    }

    console.log("We are working");
  };
  const getData = useCallback(async () => {
    setLoading(true);
    try {
      let res = await getStudentProfileInfoData(
        token,
        phone,
        userInstance.schoolId,
        "attachment"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        setBioData(res.data);

        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
        console.log("something is wrong");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.data.Message);
    }
  }, [getStudentProfileInfoData, phone, token, userInstance.schoolId]);

  const uploadFile = async () => {
    let data = {
      schoolId: userInstance.schoolId,
      fileName: fileName,
      fileContent: base64,
      studentId: userInstance.studentId,
    };

    try {
      setFormLoading(true);
      console.log(data);

      const res = await uploadAttach(token, data);
      console.log(res);
      if (res.status === true) {
        console.log("it's true");
        setFormLoading(false);

        toast.success(res.message);
        setBase64("");
        setBaseName("");
        setFileName("");
        getData();
        console.log("it's true");
      } else {
        setFormLoading(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      setFormLoading(false);
      toast.error("Please Try Again Later");
      console.log(error);
    }
  };

  const deleteAttachment = async (fileId) => {
    try {
      setDelLoading(true);
      const res = await deleteAttach(token, fileId);
      console.log(res);
      if (res.status === true) {
        console.log("it's true");
        setDelLoading(false);
        toast.success(res.message);
        getData();
        console.log("it's true");
      } else {
        setDelLoading(false);
        toast.error(res.data.message);
      }
    } catch (error) {
      setDelLoading(false);
    }
  };
  useEffect(() => {
    getData();
    // calculateAge();
  }, [getData]);

  return (
    <>
      <AttachmentFlex>
        <AttachmentUploadContainer onClick={pickFile}>
          {base64.length === 0 && (
            <AiFillFileAdd
              style={{ color: colors.primary, fontSize: "40px" }}
            />
          )}
          {(baseName.includes(".doc") ||
            baseName.includes(".docx") ||
            baseName.includes(".pdf")) && (
            <AiFillFileAdd
              style={{ color: colors.primary, fontSize: "40px" }}
            />
          )}
          {base64.length > 0 &&
            (baseName.includes(".jpg") ||
              baseName.includes(".jpeg") ||
              baseName.includes(".png")) && <img src={base64} alt="" />}

          {baseName.includes(".doc" || ".docx" || ".pdf") && (
            <a
              href={base64}
              target="_blank"
              rel="noreferrer"
              style={{ color: colors.primary }}
            >
              View File
            </a>
          )}

          {/* <UploadFileRounded /> */}
          {/* <p
            style={{
              fontSize: "20px",
              // color: colors.secondary,
              // fontWeight: "bold",
            }}
          >
            Upload File
          </p> */}
          <input
            type="file"
            style={{
              display: "none",
            }}
            onChange={onPickFile}
            ref={onPickFileRef}
          />
        </AttachmentUploadContainer>
        <p
          style={{
            fontWeight: "bold",
            color: colors.primary,
            marginTop: "0.2rem",
          }}
        >
          Click On The Box Above
        </p>
        <InlineFormDiv>
          <div style={{ width: isMobile ? "100%" : "70%" }}>
            <InputWrapper>
              <label htmlFor="filename">
                File Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                placeholder="Enter File Name e.g Birth Certificate"
              />
            </InputWrapper>
          </div>

          <AuthButton
            width={isMobile ? "100%" : "20%"}
            onClick={uploadFile}
            disabled={formLoading || base64 === "" || fileName === ""}
          >
            {formLoading ? <FormLoader /> : "Upload File"}
          </AuthButton>
        </InlineFormDiv>
        <AccountSummaryWrapper>
          {loading ? (
            <Loader />
          ) : (
            <table>
              <tr
                className="table-head"
                style={{
                  color: "black",
                }}
              >
                <th>S/N</th>
                <th>File Name</th>
                <th>Upload Date</th>
                <th></th>
              </tr>
              {bioData.length > 0 ? (
                bioData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    <td style={{ fontWeight: "bold", color: colors.primary }}>
                      {item.fileName}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {moment(item.updateDate).format("YYYY/MM/DD LT")}
                    </td>
                    <td>
                      <ButtonFlex
                        style={{
                          justifyContent: "space-around",
                          fontWeight: "bold",
                        }}
                      >
                        <a
                          href={
                            item.fileContentPath ||
                            "https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </a>
                        {item.allowDelete == 1 && (
                          <button
                            onClick={() => {
                              setId(item.id);
                              deleteAttachment(item.id);
                            }}
                            disabled={delLoading}
                            style={{
                              color: "white",
                              backgroundColor: "red",
                              borderColor: "red",
                            }}
                          >
                            {delLoading && id == item.id ? (
                              <FormLoader />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        )}
                      </ButtonFlex>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      textAlign: "center",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                  >
                    No Attachment For This Student
                  </td>
                </tr>
              )}
            </table>
          )}
        </AccountSummaryWrapper>
      </AttachmentFlex>
    </>
  );
};

export default connect(null, {
  getStudentProfileInfoData,
  uploadAttach,
  deleteAttach,
})(Attachments);
