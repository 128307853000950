import {
  FETCHING_USER,
  FETCHING_USER_DONE,
  FAILED_FETCHING_USER,
} from "../types";
import api from "../api/user";
import { toast } from "react-toastify";
export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("lasmabAuthStatus");
  return dispatch({
    type: FAILED_FETCHING_USER,
    error: "error",
    loading: false,
    // user: null,
  });
};

export const postSendCode =
  (phoneNumber, email, countryCode, accountType) => () => {
    return api.postSendCode(phoneNumber, email, countryCode, accountType);
  };

export const postVerifyCode = (phoneNumber, code, accountType) => () => {
  return api.postVerifyCode(phoneNumber, code, accountType);
};
export const postCreatePin = (phone, pin, token, accountType) => () => {
  return api.postCreatePin(phone, pin, token, accountType);
};
export const studentLogin =
  (phone, pin, token, accountType) => async (dispatch) => {
    return api
      .authStudentLogin(phone, pin, token, accountType)
      .then((res) => {
        console.log(res);

        if (res.status && res.data.isLogin) {
          let studentObj = {
            classId: res.data.classId,
            schoolId: res.data.schoolId,
            studentImage: res.data.studentImagePath,
            studentId: res.data.studentId,
          };
          dispatch({
            type: FETCHING_USER_DONE,
            user: studentObj,
            token: res.data.authToken,
            authStatus: true,
            loading: false,
            error: null,
          });
        }

        return res;
      })
      .catch((err) => console.log(err));
  };
export const getStudentProfile = (token) => async (dispatch) => {
  dispatch({
    type: FETCHING_USER,
    loading: true,
  });
  console.log("get student profile");
  return api
    .getStudentProfile(token)
    .then((res) => {
      console.log(res);
      if (res.status === true && res.data) {
        localStorage.setItem("lasmabObj", JSON.stringify(res.data));
        dispatch({
          type: FETCHING_USER_DONE,
          user: res.data,
          token: token,
          authStatus: true,
          loading: false,
          error: null,
        });
      } else {
        localStorage.removeItem("lasmabAuthStatus");
        dispatch({
          type: FAILED_FETCHING_USER,
          error: "error",
          loading: false,
          // user: null,
        });
        toast.error(res?.data?.message || res?.message);
        console.log("something is wrong here");

        return res;
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
};

export const postForgetPin = (phoneNumber) => () => {
  return api.postForgetPin(phoneNumber);
};

export const postResetPin = (data) => () => {
  return api.postResetPin(data);
};

export const changePin = (token, data, browserToken) => () => {
  return api.postChangePin(token, data, browserToken);
};

export const getStudentMedicalInfo =
  (token, studentId, schoolId, infoType) => () => {
    return api.getStudentMedicalInfo(token, studentId, schoolId, infoType);
  };

export const getStudentProfileInfoData =
  (token, phoneNumber, schoolId, infoType) => () => {
    return api.getStudentProfileInfoData(
      token,
      phoneNumber,
      schoolId,
      infoType
    );
  };

export const getClassworkList = (token, studentId, schoolId) => () => {
  return api.getClassworkList(token, studentId, schoolId);
};

export const submitClassworkAnswer =
  (token, answer, id, studentId, questionId, type) => () => {
    return api.submitClassworkAnswer(
      token,
      answer,
      id,
      studentId,
      questionId,
      type
    );
  };

export const getClassworkQuestions = (token, classWorkId, studentId) => () => {
  return api.getClassworkQuestions(token, classWorkId, studentId);
};

export const submitClassworkComment =
  (token, comment, id, senderId, senderUserType) => () => {
    return api.submitClassworkComment(
      token,
      comment,
      id,
      senderId,
      senderUserType
    );
  };

export const getClassworkComments = (token, classworkId) => () => {
  return api.getClassworkComments(token, classworkId);
};

export const getSingleClasswork =
  (token, classWorkId, schoolId, studentId) => () => {
    return api.getSingleClasswork(token, classWorkId, schoolId, studentId);
  };

export const getAssignmentList = (token, studentId, schoolId) => () => {
  return api.getAssignmentList(token, studentId, schoolId);
};

export const getSingleAssignment = (token, id, schoolId, studentId) => () => {
  return api.getSingleAssignment(token, id, schoolId, studentId);
};

export const getAssignmentQuestions = (token, id, studentId) => () => {
  return api.getAssignmentQuestions(token, id, studentId);
};

export const submitAssignmentAnswer =
  (token, answer, id, studentId, questionId, type) => () => {
    return api.submitAssignmentAnswer(
      token,
      answer,
      id,
      studentId,
      questionId,
      type
    );
  };

export const submitAssignmentComment =
  (token, comment, id, senderId, senderUserType) => () => {
    return api.submitAssignmentComment(
      token,
      comment,
      id,
      senderId,
      senderUserType
    );
  };

export const getAssignmentComments = (token, assignmentId) => () => {
  return api.getAssignmentComments(token, assignmentId);
};

export const getTutorial = (token, classId, schoolId) => () => {
  return api.getTutorial(token, classId, schoolId);
};

export const getTutorialComments = (token, schoolId, tutorialId) => () => {
  return api.getTutorialComments(token, schoolId, tutorialId);
};

export const submitTutorialComment =
  (token, comment, senderId, senderUserType, id) => () => {
    return api.submitTutorialComment(
      token,
      comment,
      senderId,
      senderUserType,
      id
    );
  };

export const uploadAttach = (token, data) => () => {
  return api.uploadAttachment(token, data);
};

export const deleteAttach = (token, id) => () => {
  return api.deleteAttachment(token, id);
};

export const uploadFileAnswer =
  (
    token,
    studentId,
    schoolId,
    classId,
    fileName,
    fileContentPath,
    attachmentTypeId,
    AnsType
  ) =>
  () => {
    return api.uploadFileAnswer(
      token,
      studentId,
      schoolId,
      classId,
      fileName,
      fileContentPath,
      attachmentTypeId,
      AnsType
    );
  };

export const getStudentLedger =
  (token, schoolId, studentId, dateFrom, dateTo) => () => {
    return api.getStudentLedger(token, schoolId, studentId, dateFrom, dateTo);
  };

export const getSchoolProfile = (token, id) => () => {
  return api.getSchoolProfile(token, id);
};

export const getResults = (token, sessionId) => () => {
  return api.getResults(token, sessionId);
};

export const viewResult = (token, data) => () => {
  return api.viewResult(token, data);
};

export const viewGuides = (token, accountType) => () => {
  return api.getUserGuides(token, accountType);
};

export const viewGuideLink = (token, accountType, qid) => () => {
  return api.getUserGuideLink(token, accountType, qid);
};

export const fetchStampSign = (token, schoolId) => () => {
  return api.getSchoolStampAndSignature(token, schoolId);
};
