export const paginateData = (
  data,
  setList,
  currentPage,
  offSet,
  setOffSet,
  setPageCount
) => {
  const itemsPerPage = 40;

  if (data?.length > 0) {
    const endOffSet = offSet + itemsPerPage;
    console.log(`Loading items from ${offSet} to ${endOffSet}`);
    console.log(currentPage);
    const newOffSet = (currentPage * itemsPerPage) % data.length;
    console.log(newOffSet);
    console.log(
      `User requested page number ${currentPage}, which is offset ${newOffSet}`
    );

    const trimStart = (currentPage - 1) * itemsPerPage;
    const trimEnd = trimStart + itemsPerPage;

    const arr = data.slice(trimStart, trimEnd);
    console.log(arr);
    setList(arr);
    setOffSet(newOffSet);
  }
  setPageCount(Math.ceil(data?.length / itemsPerPage));
};

export const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};
