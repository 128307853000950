/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import DownArrow from "../../assets001/icons/down-arrow";
import { connect } from "react-redux";
import colors from "../../assets001/colors.json";
import {
  PageWrapper,
  ProfileWrapper,
  WrapperCenter,
  WrapperLeft,
  WrapperRight,
  InlineLabelGroup,
  InlineLabelWrapper,
  AccordionText,
  FormFlex,
  FormFlexDiv,
  TabContentWrapper,
} from "../../pages/student-Dashboard/styles/dashboard.style";
import ArrowUp from "../../assets001/icons/up-arrow";
import { InputWrapper, AuthButton } from "../../pages/auth/auth.style";
import Select from "../select/select";
import DatePickerComponent from "../datepicker/DatePicker";
import { useMediaQuery } from "react-responsive";
import { getStudentProfileInfoData } from "../../actions/user";
import Loader from "../pageLoader/loader";
import { toast } from "react-toastify";
const Health = ({ getStudentProfileInfoData }) => {
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const phone = localStorage.lasmabPhone || "";
  const token = localStorage.lasmabAuthToken || "";
  const [dob, setDob] = useState(new Date(2014, 0, 3));
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const isTab = useMediaQuery({ maxWidth: 768 });
  const [loading, setLoading] = useState(true);
  const [bioData, setBioData] = useState(null);
  const getData = async () => {
    try {
      let res = await getStudentProfileInfoData(
        token,
        phone,
        userInstance.schoolId,
        "health-data"
      );
      console.log(res);
      if (res.status === true && res.statusCode === 2) {
        console.log(res.data);
        setBioData(res.data);
        setLoading(false);
      } else {
        toast.error(res.data.Message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const optionsArr = [
    {
      id: 1,
      name: "Yes",
      value: "true",
    },
    {
      id: 2,
      name: "No",
      value: "false",
    },
  ];

  useEffect(() => {
    getData();
  }, []);
  const [dataShow, setDataShow] = useState({
    accId: 1,
    accName: "accOne",
  });
  const [opt, setOpt] = useState("");

  return (
    <TabContentWrapper>
      {/* Accordion One */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 1 && dataShow.accName === "accOne") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 1,
                accName: "accOne",
              });
            }
          }}
        >
          <WrapperLeft>Immunization history</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 1 ? <ArrowUp /> : <DownArrow />}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accOne" && (
          <>
            {loading ? (
              <Loader />
            ) : bioData ? (
              <PageWrapper>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">BCG</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      <label htmlFor="">Status</label>
                      <input
                        type="text"
                        placeholder="BCG Status"
                        value={bioData.bcgStatus}
                        onChange={(e) => console.log(e.target.value)}
                        disabled={true}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">Date</label>
                      <DatePickerComponent
                        disabled={true}
                        selected={new Date(bioData.bcgDate)}
                        setSelected={(val) => {
                          setDob(val);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">DPT</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      {/* <label htmlFor="">Status</label> */}
                      <input
                        type="text"
                        placeholder="DPT Status"
                        value={bioData.dptStatus}
                        onChange={(e) => console.log(e.target.value)}
                        disabled={true}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      {/* <label htmlFor="">Date</label> */}
                      <DatePickerComponent
                        disabled={true}
                        selected={new Date(bioData.dptDate)}
                        setSelected={(val) => {
                          setDob(val);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">Oral Polio</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      {/* <label htmlFor="">Status</label> */}
                      <input
                        type="text"
                        placeholder="Oral Polio Status"
                        value={bioData.oralPolioStatus}
                        onChange={(e) => console.log(e.target.value)}
                        disabled={true}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      {/* <label htmlFor="">Date</label> */}
                      <DatePickerComponent
                        disabled={true}
                        selected={new Date(bioData.oralPolioDate)}
                        setSelected={(val) => {
                          setDob(val);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <label htmlFor="">Yellow Fever</label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    {" "}
                    <InputWrapper>
                      <label htmlFor="">Status</label>
                      <input
                        type="text"
                        placeholder="Yellow Fever Status"
                        value={bioData.yellowFeverStatus}
                        onChange={(e) => console.log(e.target.value)}
                        disabled={true}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">Date</label>
                      <DatePickerComponent
                        disabled={true}
                        selected={new Date(bioData.yellowFeverDate)}
                        setSelected={(val) => {
                          setDob(val);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
              </PageWrapper>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                No Immunization History For Student
              </div>
            )}
          </>
        )}
      </div>
      {/* Accordion Two */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 2 && dataShow.accName === "accTwo") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 2,
                accName: "accTwo",
              });
            }
          }}
        >
          <WrapperLeft>Personal Medical history</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 2 ? <ArrowUp /> : <DownArrow />}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accTwo" && (
          <>
            {loading ? (
              <Loader />
            ) : bioData ? (
              <PageWrapper>
                <AccordionText>
                  Does the child suffer from any of the following?
                </AccordionText>
                {/* 1 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Statusle Cell Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={
                            bioData.isSickleCell === true ? "Yes" : "No"
                          }
                          disabled={true}
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Disease Of The Eye</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          disabled={true}
                          selected={
                            bioData.hasEyeDisease === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                {/* 2 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Epilepsy</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={bioData.hasEpilespy === true ? "Yes" : "No"}
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Disease Of the Ear</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          disabled={true}
                          selected={
                            bioData.hasEarDisease === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                {/* 3 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Diabetics Meitus</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={
                            bioData.hasDiabetics === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">
                            Disease of the Nose and throat
                          </label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={
                            bioData.hasNoseDisease === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                {/* 4 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Tuberculosis</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={
                            bioData.hasTuberculosis === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Respiratory Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          selected={
                            bioData.hasRespiratoryDisease === true
                              ? "Yes"
                              : "No"
                          }
                          options={optionsArr}
                          disabled={true}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>{" "}
                {/* 5 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Heart Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          index="name"
                          disabled={true}
                          selected={
                            bioData.hasHeartDisease === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">
                            Alergies e.g Asthmaor Skin Dissease
                          </label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={bioData.hasAlergies === true ? "Yes" : "No"}
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>{" "}
                {/* 6 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Kidney Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={
                            bioData.hasKidneyDisease === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>{" "}
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">Mental or Nervous Disease</label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={
                            bioData.hasMentalOrNervousDisease === true
                              ? "Yes"
                              : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>{" "}
                {/* 7 */}
                <FormFlex>
                  <FormFlexDiv width="45%">
                    <InlineLabelGroup>
                      <InlineLabelWrapper flex={1}>
                        <InputWrapper>
                          <label htmlFor="">
                            Disease of the Digestive System
                          </label>
                        </InputWrapper>
                      </InlineLabelWrapper>
                      <InlineLabelWrapper flex={1}>
                        <Select
                          title="Status"
                          disabled={true}
                          index="name"
                          selected={
                            bioData.hasDigestiveDisease === true ? "Yes" : "No"
                          }
                          options={optionsArr}
                          setSelected={(val) => {
                            console.log(val);
                            setOpt(val.name);
                          }}
                        />
                      </InlineLabelWrapper>
                    </InlineLabelGroup>
                  </FormFlexDiv>
                </FormFlex>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Does your child has any typr of Handicap?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        disabled={true}
                        index="name"
                        selected={
                          bioData.isChild_HandCap === true ? "Yes" : "No"
                        }
                        options={optionsArr}
                        setSelected={(val) => {
                          console.log(val);
                          setOpt(val.name);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Has your child been admitted into a hospital before?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        disabled={true}
                        index="name"
                        selected={
                          bioData.isChildAdmitted_To_Hospital === true
                            ? "Yes"
                            : "No"
                        }
                        options={optionsArr}
                        setSelected={(val) => {
                          console.log(val);
                          setOpt(val.name);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Has your child been operated on before?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        disabled={true}
                        index="name"
                        selected={
                          bioData.isChildOperatedBefore === true ? "Yes" : "No"
                        }
                        options={optionsArr}
                        setSelected={(val) => {
                          console.log(val);
                          setOpt(val.name);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
                {/* If Yes */}
                {/*  */}
                <FormFlex>
                  <FormFlexDiv width="100%">
                    <InputWrapper>
                      <label htmlFor="">If Yes give details</label>
                      <textarea
                        cols="30"
                        disabled={true}
                        rows="3"
                        value={bioData.operationDetails}
                        onChange={(e) => console.log(e.target.value)}
                      ></textarea>
                    </InputWrapper>
                  </FormFlexDiv>
                </FormFlex>
                <InlineLabelGroup>
                  <InlineLabelWrapper flex={2}>
                    <InputWrapper>
                      <label htmlFor="">
                        Is your child on any special medication?
                      </label>
                    </InputWrapper>
                  </InlineLabelWrapper>
                  <InlineLabelWrapper flex={1}>
                    <InputWrapper>
                      <Select
                        title="Status"
                        disabled={true}
                        index="name"
                        selected={
                          bioData.isChildOnMedication === true ? "Yes" : "No"
                        }
                        options={optionsArr}
                        setSelected={(val) => {
                          console.log(val);
                          setOpt(val.name);
                        }}
                      />
                    </InputWrapper>
                  </InlineLabelWrapper>
                </InlineLabelGroup>
              </PageWrapper>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                No Personal Medical History For Student
              </div>
            )}
          </>
        )}
      </div>
      {/* Accordion Three */}
      <div style={{ marginTop: "5px" }}>
        <ProfileWrapper
          onClick={() => {
            if (dataShow.accId === 3 && dataShow.accName === "accThree") {
              setDataShow({
                accId: "",
                accName: "",
              });
            } else {
              setDataShow({
                accId: 3,
                accName: "accThree",
              });
            }
          }}
        >
          <WrapperLeft>Others</WrapperLeft>
          <WrapperCenter></WrapperCenter>
          <WrapperRight>
            {dataShow.accId === 3 ? <ArrowUp /> : <DownArrow />}
          </WrapperRight>
        </ProfileWrapper>
        {dataShow.accName === "accThree" && (
          <>
            {loading ? (
              <Loader />
            ) : bioData ? (
              <PageWrapper>
                <FormFlex>
                  <FormFlexDiv width="100%">
                    <InputWrapper>
                      <label htmlFor="Common Family Ailment(s)">
                        Common Family Ailment(s)
                      </label>
                      <input
                        type="text"
                        value={bioData.familyAilment}
                        onChange={(e) => console.log(e.target.value)}
                        disabled={true}
                        placeholder="Common Family Ailment(s)"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Current status of ailment">
                        Current status of ailment
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={bioData.alimentStatus}
                        placeholder="Common Family Ailment(s)"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Blood Group">Blood Group</label>
                      <input
                        type="text"
                        value={bioData.bloodGroup}
                        disabled={true}
                        onChange={(e) => console.log(e.target.value)}
                        placeholder="Child Known ailment"
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <label htmlFor="">
                        Medical advice on child's ailment
                      </label>
                      <textarea
                        cols="30"
                        rows="3"
                        value={bioData.medicalAdvise}
                        disabled={true}
                        onChange={(e) => console.log(e.target.value)}
                      ></textarea>
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Child Known ailment">
                        Child Known ailment
                      </label>
                      <input
                        type="text"
                        value={bioData.childAilment}
                        onChange={(e) => console.log(e.target.value)}
                        disabled={true}
                        placeholder="Child Known ailment"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Child's Allergic reaction(if any)">
                        Child's Allergic reaction(if any)
                      </label>
                      <input
                        type="text"
                        disabled={true}
                        value={bioData.childAllergicReaction}
                        onChange={(e) => console.log(e.target.value)}
                        placeholder="Child's Allergic reaction(if any)"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <label htmlFor="Genotype">Genotype</label>
                      <input
                        type="text"
                        onChange={(e) => console.log(e.target.value)}
                        value={bioData.genotype}
                        disabled={true}
                        placeholder="Child Known ailment"
                      />
                    </InputWrapper>
                  </FormFlexDiv>
                </FormFlex>
              </PageWrapper>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  textAlign: "center",
                }}
              >
                No Other Medical Report For Student
              </div>
            )}
          </>
        )}
      </div>

      <div
        style={{
          display: "none",
          justifyContent: "end",
        }}
      >
        <AuthButton
          disabled={false}
          width={isMobile ? "100%" : "30%"}
          onSubmit={null}
        >
          <div>
            <span>Update Details</span>
          </div>
          {/* Send Code */}
        </AuthButton>
      </div>
    </TabContentWrapper>
  );
};

export default connect(null, { getStudentProfileInfoData })(Health);
