import "./App.css";
import Routes from "./routes";
// import { useEffect } from "react";
import { connect } from "react-redux";
// import { testApi } from "./actions/user";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  // useEffect(() => {
  //   setTimeout(() => {
  //     testApi();
  //   }, 4000);
  // }, []);

  return (
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="colored"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.userState.loading,
});
export default connect(mapStateToProps, {
  // testApi,
})(App);
