import React, { useState, useCallback, useEffect } from "react";
import {
  BigHeader,
  Container,
  InlineLabelGroup,
  InlineLabelWrapper,
  SmallHeader,
  TabContainer,
  LedgerContainer,
  LedgerHeader,
  LedgerSchoolName,
  LedgerSchoolInfo,
  LedgerSchoolHead,
  LedgerStudentHeader,
  LedgerStudentDiv,
  LedgerInfoDetails,
  LedgerFlexInfo,
  LedgerTable,
  AccountSummaryWrapper,
} from "./styles/dashboard.style";
import moment from "moment";
import GlobalStyles from "../../assets001/globalStyle";
import colors from "../../assets001/colors.json";
import { connect } from "react-redux";
import { getStudentLedger, getSchoolProfile } from "../../actions/user";
import { toast } from "react-toastify";
import { AuthButton, InputWrapper, ButtonFlex } from "../auth/auth.style";
import DatePickerComponent from "../../components/datepicker/DatePicker";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets001/school-logo.png";
import { GiPostOffice } from "react-icons/gi";
import { HiMail } from "react-icons/hi";
import { BsTelephoneFill, BsGlobe } from "react-icons/bs";
import avatar from "../../assets001/avatar.jpeg";
import FormLoader from "../../components/FormLoader";
import Loader from "../../components/pageLoader/loader";
import WelcomeText from "../../components/WelcomeText";
const Ledger = ({ getStudentLedger, user, pLoading, getSchoolProfile }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const token = localStorage.lasmabAuthToken || "";
  const userInstance = JSON.parse(localStorage.lasmabObj) || {};
  const [loading, setLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date(2019, 2, 3));
  const [dateTo, setDateTo] = useState(new Date());
  const [arr, setArr] = useState([]);
  const [obj, setObj] = useState(null);
  const [objLoading, setObjLoading] = useState(true);

  const schoolProfile = async () => {
    try {
      // setLoading(true);
      let res = await getSchoolProfile(token, userInstance.schoolId);
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setObj(res.data);
        setObjLoading(false);
      } else {
        toast.error("Error Getting School Profile");
        setObjLoading(false);
      }
    } catch (error) {
      setObjLoading(false);
      console.log(error);
      toast.error("An Error Occured");
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      let res = await getStudentLedger(
        token,
        userInstance.schoolId,
        userInstance.studentId,
        dateFrom.toISOString(),
        dateTo.toISOString()
      );
      console.log(res);
      if (res.status && res.statusCode === 2) {
        setArr(res.data);
        setLoading(false);
      } else {
        toast.error("No Ledger Record Found");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An Error Occured");
    }
  };
  useEffect(() => {
    schoolProfile();
    getData();
  }, []);

  return (
    <Container>
      <GlobalStyles />
      <div style={{ borderBottom: "2px solid #671E78", marginBottom: "40px" }}>
        <WelcomeText />
        {/* <SmallHeader>Hi there! Welcome</SmallHeader> */}
        <BigHeader>Ledger</BigHeader>
      </div>

      <TabContainer
        style={{
          marginTop: "1rem",
          background: colors.primary,
        }}
      >
        <InlineLabelGroup>
          <InlineLabelWrapper flex={2}>
            <InlineLabelGroup>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Start Date
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dateFrom}
                    setSelected={(val) => {
                      setDateFrom(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
              <InlineLabelWrapper flex={1}>
                <InputWrapper>
                  <label
                    htmlFor=""
                    style={{
                      color: "white",
                    }}
                  >
                    Stop Date
                  </label>
                  <DatePickerComponent
                    bg={colors.primary}
                    useBorder={true}
                    color="white"
                    selected={dateTo}
                    setSelected={(val) => {
                      setDateTo(val);
                    }}
                  />
                </InputWrapper>
              </InlineLabelWrapper>
            </InlineLabelGroup>
          </InlineLabelWrapper>
          <InlineLabelWrapper flex={1}>
            <ButtonFlex>
              <AuthButton
                style={{ height: "48px" }}
                disabled={dateFrom > dateTo}
                width={isMobile ? "100%" : "90%"}
                onClick={() => getData()}
                color={colors.primary}
                bg="white"
              >
                {" "}
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {loading ? (
                    <FormLoader color={colors.primary} />
                  ) : (
                    "Generate Ledger"
                  )}
                </div>
              </AuthButton>
            </ButtonFlex>
          </InlineLabelWrapper>
        </InlineLabelGroup>
      </TabContainer>
      <LedgerContainer>
        <LedgerHeader
          style={{ backgroundColor: objLoading ? "white" : colors.primary }}
        >
          {obj !== null ? (
            <div>
              <LedgerSchoolName>
                <div>
                  <img
                    src={obj.logo !== null ? obj.logo : logo}
                    alt="school-logo"
                    style={{ marginLeft: "4px" }}
                  />
                </div>
                <span>{obj.schoolname}</span>
              </LedgerSchoolName>
              <LedgerSchoolInfo>
                <div>
                  <LedgerSchoolHead justifyContent="end">
                    <div>
                      <span> {obj.schooladdress}</span>
                    </div>
                    <div>
                      <GiPostOffice />
                    </div>
                  </LedgerSchoolHead>
                  <LedgerSchoolHead justifyContent="end">
                    <div>
                      <span>{obj.schoolemail}</span>
                    </div>
                    <div>
                      <HiMail />
                    </div>
                  </LedgerSchoolHead>
                </div>
                <div>
                  <LedgerSchoolHead>
                    <div>
                      <BsTelephoneFill />
                    </div>
                    <div>
                      <span>{obj.schoolphone}</span>
                    </div>
                  </LedgerSchoolHead>
                  <LedgerSchoolHead>
                    <div>
                      <BsGlobe />
                    </div>
                    <div>
                      <span>{obj.portal_url}</span>
                    </div>
                  </LedgerSchoolHead>
                </div>
              </LedgerSchoolInfo>
            </div>
          ) : objLoading ? (
            <Loader />
          ) : (
            <div>
              <p
                style={{ fontSize: "20px", color: "white", fontWeight: "bold" }}
              >
                Error Getting School Profile
              </p>
            </div>
          )}
        </LedgerHeader>
        {user !== null ? (
          <LedgerStudentHeader>
            <div>
              <LedgerStudentDiv>
                <div
                  style={{
                    width: "35%",
                    height: "160px",
                    borderRadius: "20px",
                  }}
                >
                  <img src={user.studentImagePath} alt="" />
                </div>
                <div
                  style={{
                    width: "60%",
                    padding: "0.4rem",
                  }}
                >
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={1}>
                      <p>Name:</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={2}>
                      <span>{user.studentName}</span>{" "}
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={1}>
                      <p>House</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={2}>
                      <span>Babington</span>
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={1}>
                      <p>Term:</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={2}>
                      <span>Third Term</span>
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={1}>
                      <p>Class</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={2}>
                      <span>JSS 1</span>
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                </div>
              </LedgerStudentDiv>
              <LedgerStudentDiv>
                <div
                  style={{
                    width: "100%",
                    padding: "0.4rem",
                  }}
                >
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={2}>
                      <p>Admission No:</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={1}>
                      <span>{user.admissionNumber} </span>{" "}
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={2}>
                      <p>Academic Session</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={1}>
                      <span>2021/2022</span>{" "}
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={2}>
                      <p>Opening Balance:</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={1}>
                      <span>20,000</span>{" "}
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                  <LedgerInfoDetails>
                    <LedgerFlexInfo flex={2}>
                      <p>Closing Balance:</p>
                    </LedgerFlexInfo>
                    <LedgerFlexInfo flex={1}>
                      <span>20,000</span>{" "}
                    </LedgerFlexInfo>
                  </LedgerInfoDetails>
                </div>
              </LedgerStudentDiv>
            </div>
          </LedgerStudentHeader>
        ) : pLoading ? (
          <p>Getting Student Info</p>
        ) : (
          <p>Error Getting Student Info</p>
        )}

        <LedgerTable>
          <AccountSummaryWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              <table>
                <tr
                  className="table-head"
                  style={{
                    color: "black",
                  }}
                >
                  <th>Posting Date</th>
                  <th>Transaction Date</th>
                  <th>Reference No</th>
                  {!isMobile && <th>Narration</th>}

                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                </tr>
                {arr.length > 0 ? (
                  arr.map((item, index) => (
                    <tr key={index}>
                      <th>{moment(item.postingDate).format("YYYY/MM/DD")}</th>
                      <th>{moment(item.transDate).format("YYYY/MM/DD")}</th>
                      {/* <th>12/2/2022</th> */}
                      <th>{item.referenceNumber}</th>
                      {!isMobile && <th>{item.narration}</th>}
                      <th
                        style={{
                          color: "rgba(255, 0,0, 0.9)",
                        }}
                      >
                        {item.debit.toLocaleString()}
                      </th>
                      <th
                        style={{
                          color: "rgba(0, 255, 0, 0.8)",
                        }}
                      >
                        {item.credit.toLocaleString()}
                      </th>
                      <th>{item.balance.toLocaleString()}</th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <th
                      colSpan={!isMobile ? 7 : 6}
                      style={{ textAlign: "center" }}
                    >
                      NO Ledger Records Available At The Moment
                    </th>
                  </tr>
                )}
              </table>
            )}
          </AccountSummaryWrapper>
        </LedgerTable>
      </LedgerContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.userState.user,
  pLoading: state.userState.loading,
});

export default connect(mapStateToProps, { getStudentLedger, getSchoolProfile })(
  Ledger
);
