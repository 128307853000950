import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import { useNavigate, useLocation } from "react-router-dom";
import UserGuideButton from "../UserGuide/UserGuideButton";
import Avatar from "@mui/material/Avatar";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Box, Container } from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import InputBase from "@mui/material/InputBase";
import {
  Grid,
  useMediaQuery,
  useTheme,
  AppBar,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";
import { logoutAction } from "../../actions/user";
import { connect } from "react-redux";

import Drawers from "./Drawer";
import ChangePin from "./ChangePin";

const DashboardNavbar = ({ controlNav, logoutAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const logout = async () => {
    console.log("Logout");
    // localStorage.removeItem("lasmabAuthStatus");
    logoutAction();
    navigate("/");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("992"));
  return (
    <>
      <AppBar
        sx={{
          background: "#ffffff",
          color: "#000",
          boxShadow: "none",
          width: { lg: "78%", md: "70%" },
          display: "flex",
          justifyContent: "center",
          // alignContent: "center",
          height: { lg: "100px", md: "70px", sm: "70px", xs: "70px" },
        }}
      >
        {isMatch ? (
          <>
            <Box> </Box>
            <Drawers />
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", width: "95%" }}>
              <Container>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <UserGuideButton />
                  </Grid>

                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={3}
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      ml: "auto",
                    }}
                  >
                    <Badge badgeContent={2} color="error">
                      <NotificationsNoneIcon />
                    </Badge>
                    <Avatar src="https://res.cloudinary.com/dgkcytiv6/image/upload/v1693005681/qkgkhvblmekt5uxj4w30_eucaxm.jpg" />
                    <IconButton onClick={handleClick}>
                      <MenuIcon
                        style={{ fontSize: "25px", cursor: "pointer" }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuList>
                <MenuItem sx={{ fontSize: "15px" }} onClick={logout}>
                  <LogoutIcon sx={{ mr: 1 }} /> Logout
                </MenuItem>
                <ChangePin />
                {/* <MenuItem sx={{ fontSize: "15px" }}>
                  <LockOpenIcon sx={{ mr: 1 }} /> Change Pin
                </MenuItem> */}
              </MenuList>
            </Popover>
          </>
        )}
      </AppBar>
    </>
  );
};

export default connect(null, { logoutAction })(DashboardNavbar);
